import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import "./DashboardDoughnut.css";

import "chartjs-plugin-labels";

import { useTranslation } from "react-i18next";
import { commanderDashboardMaximumElementsForIndicator } from "../../../Configurations/consts";
import ELanguage from "../../../Enums/ELanguage";

interface IDoughunt {
  label: string;
  value: number;
  color: "red" | "green" | "yellow" | "notAttendedColor" | string;
}

interface Doughunt {
  chartData: IDoughunt[];
  indicatorName: string;
  indicatorId: number;
  selectedIndicator: number | undefined;
  setSelectedIndicator: Dispatch<SetStateAction<number | undefined>>;
  isDisableAll: boolean;
}

const DashboardDoughnut = (props: Doughunt) => {
  const {
    chartData,
    indicatorId,
    indicatorName,
    selectedIndicator,
    setSelectedIndicator,
    isDisableAll,
  } = props;

  const { i18n, t } = useTranslation();

  let emptyDoughnut = new Array(
    commanderDashboardMaximumElementsForIndicator
  ).fill({
    color: "gray",
    label: "",
    value: 0,
  });

  const [doughnutData, setDoughnutData] = useState<IDoughunt[]>(emptyDoughnut);
  useEffect(() => {
    setDoughnutData(
      chartData?.length
        ? chartData.slice(0, commanderDashboardMaximumElementsForIndicator)
        : emptyDoughnut
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  const getChartData = () => {
    let data: number[] = [];
    doughnutData.forEach((t) => {
      let val =
        t.value > 85 && t.value !== 100
          ? 80
          : t.value < 23 && t.value !== 0
          ? 23
          : t.value;

      let part = 100 / doughnutData.length;

      data.push((Number(val) * part) / 100);
      data.push(part - (Number(val) * part) / 100);
    });
    return data;
  };
  const getChartDataOutside = () => {
    let data: number[] = [];
    doughnutData.forEach(() => {
      data.push(100 / doughnutData.length);
    });
    return data;
  };
  const getbackgroundColor = () => {
    let colors: string[] = [];
    doughnutData.forEach((t) => {
      switch (t.color) {
        case "green":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#2c4234") //disabled
            : colors.push("#06d114"); //color
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#303337") //disabled background
            : colors.push("#303E36"); //background
          break;
        case "yellow":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#443d33")
            : colors.push("#f5a30c");
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#313337")
            : colors.push("#3E3A35");
          break;

        case "red":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#433438")
            : colors.push("#EB4141");
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#323237")
            : colors.push("#413335");
          break;

        case "notAttendedColor":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#393B3F")
            : colors.push("#4A4D54");
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#393B3F")
            : colors.push("#4A4D54");
          break;
        case "gray":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#36383B")
            : colors.push("#36383B");
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#36383B")
            : colors.push("#36383B");
          break;
      }
    });
    return colors;
  };
  const getbackgroundColorOutside = () => {
    let colors: string[] = [];
    doughnutData.forEach((t) => {
      switch (t.color) {
        case "green":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#303337") //disabled
            : colors.push("#303e36"); //color
          break;
        case "yellow":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#313337")
            : colors.push("#3e3a35");
          break;

        case "red":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#323237")
            : colors.push("#413335");
          break;
        case "notAttendedColor":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#2D3033")
            : colors.push("#3B3D43");
          break;
        case "gray":
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? colors.push("#36383B")
            : colors.push("#36383B");
          break;
      }
    });
    return colors;
  };
  const data = {
    datasets: [
      {
        data: getChartData(),
        borderWidth: 0.2,
        borderRadius: 80,
        borderColor: "#232426",
        backgroundColor: getbackgroundColor(),
      },
    ],
  };
  const outsideData = {
    datasets: [
      {
        data: getChartDataOutside(),
        borderWidth: 0.3,
        borderRadius: 80,
        borderColor: "#232426",
        backgroundColor: getbackgroundColorOutside(),
      },
    ],
  };
  // round corners and shadow
  const plugins: any = {
    beforeDraw: (chart: any, args: any, options: any) => {
      const { ctx } = chart;
      ctx.shadowColor = "rgba(0, 0, 0, 0.4)";
      ctx.shadowBlur = 10;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;
    },
    afterUpdate: function (chart: any) {
      for (let i = 0; i < chart.config.data.datasets[0].data.length; i++) {
        if (i % 2 === 0) {
          var arc = chart.getDatasetMeta(0).data[i];
          arc.round = {
            x: (chart.chartArea.left + chart.chartArea.right) / 2,
            y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
            radius: (chart.outerRadius + chart.innerRadius) / 2,
            thickness: (chart.outerRadius - chart.innerRadius) / 2,
            backgroundColor: arc._model?.backgroundColor,
          };
        }
      }
    },

    afterDraw: function (chart: any) {
      for (let i = 0; i < chart.config.data.datasets[0].data.length; i++) {
        if (
          i % 2 === 0 &&
          chart.config.data.datasets[0].data[i] <
            (100 / chart.config.data.datasets[0].data.length) * 2 &&
          chart.config.data.datasets[0].data[i] > 0
        ) {
          var ctx = chart.chart?.ctx;
          let arc = chart.getDatasetMeta(0).data[i];
          var endAngle = Math.PI / 2 - arc._view?.endAngle;
          ctx.save();
          ctx.shadowColor = "transperent";
          ctx.shadowBlur = 0;
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = 0;
          ctx.translate(arc.round.x, arc.round.y);
          ctx.fillStyle = arc.round.backgroundColor;
          ctx.beginPath();
          ctx.arc(
            arc.round.radius * Math.sin(endAngle),
            arc.round.radius * Math.cos(endAngle),
            arc.round.thickness * 0.95,
            0,
            2 * Math.PI
          );
          ctx.closePath();
          ctx.fill();
          ctx.restore();
        }
      }
    },
  };

  const clickHandler = () => {
    setSelectedIndicator((prev) =>
      prev === indicatorId ? undefined : indicatorId
    );
  };
  const reverseString = (str: string) => {
    let splitString = str.split("");
    let reverseArray = splitString.reverse();
    let joinArray = reverseArray.join("");
    return joinArray;
  };
  const options: any = {
    responsive: true,
    maintainAspectRatio: true,
    events: [],
    animation: { animateRotate: true, animationScale: true },
    rotation: Math.PI * 0.5,
    cutoutPercentage: 55,
    tooltips: {
      enabled: false,
    },
    opacity: 0.1,
    circumference: 2 * Math.PI,
    plugins: {
      datalabels: {
        display: false,
      },
      labels: {
        render: (ctx: any) => "",
        precision: 0,
        showActualPercentages: false,
        arc: true,
      },
    },
  };
  const outsideOptions: any = {
    ...options,

    cutoutPercentage: 87,
    plugins: {
      datalabels: { display: false },
      align: "center",
      anchor: "center",
      labels: {
        render: (ctx: any) => {
          return i18n.language === ELanguage.he
            ? reverseString(t(doughnutData[ctx.index].label))
            : t(doughnutData[ctx.index].label);
        },
        precision: 1,
        showActualPercentages: false,
        arc: true,
        fontColor:
          selectedIndicator !== indicatorId && selectedIndicator !== undefined
            ? "rgb(101, 102, 100)"
            : "#A6A7A7",
        fontFamily: "Regular",
      },
    },
  };
  return (
    <div
      className="doughnutDiv"
      onClick={isDisableAll ? () => {} : clickHandler}
    >
      <Doughnut
        data={outsideData}
        options={outsideOptions}
        width={10}
        height={10}
      ></Doughnut>
      <div className="pieContainer clickable">
        <Doughnut
          data={data}
          options={options}
          width={5}
          height={5}
          plugins={[plugins]}
        ></Doughnut>
      </div>
      <div className="textDiv clickable">
        <p
          className={`textThreshold ${
            selectedIndicator !== indicatorId && selectedIndicator !== undefined
              ? "textThresholdDisabled"
              : ""
          }`}
        >
          {indicatorName}
        </p>
      </div>
    </div>
  );
};

export default DashboardDoughnut;
