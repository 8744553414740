import React from "react";
import DropdownEditor from "./DropdownEditor";
import DrillsDataTable from "./DrillsDataTable";
import { useTranslation } from "react-i18next";
import customToast from "../../Shared/Toast/CustomToast";
import "./Magnet.css";
import DesktopManualInputTemplate from "./DesktopManualInputTemplate";

import {
  convertLaneToMrkANdIESTLane,
  handleSave,
  IbasicRow,
  resetRowsData,
  sendDrillToBBackend,
  XDefaultColumn,
} from "./dataPostingHelpers";
import UseDataPosting from "../../CustomHooks/UseDataPosting";
import {
  ComparisonFormatter,
  PositiveNumberFormatter,
  PositiveNumberNtoMFormatter,
} from "./InputFormatters";
import SoldiersListEditor from "./SoldiersListEditor";
import IDataPosting from "../../../Interfaces/IDataPosting";

const messageType: number = 6;
const rowsNumber: number = 14;
const trainingTypeId: number = 10;

// Mrk row (ui) interface
export interface IMagnetRow extends IbasicRow {
  laneId: number;
  weaponType: string;
  numberOfTargets: number | "";
  numberOfShots: number | "";
  numberOfHits: number | "";
  targetsDestroyed: number | "";
  hashtags: string | "";
  soldierId: number | "";
  weapons: string[];
  blueDestroyed: number | "";
  blueHits: number | "";
  damagePercentage: number | "";
}

// Mrk message interface
interface magnetMessage {
  MessageType: number;
  TimeStamp: Date;
  LaneID: number;
  TrainingTypeID: number;
  SoldierID: number | "";
  DrillStartTime: Date;
  WeaponType: string;
  NumOfTargets: number | "";
  NumOfShots: number | "";
  RedHits: number | "";
  BlueDestroyed: number | "";
  BlueHits: number | "";
  Damage: number | "";
  TargetsDestroyed: number | "";
  Hashtags: string[];
  PlanId: number | undefined;
}

const Magnet: React.FC<IDataPosting> = (props: IDataPosting): JSX.Element => {
  const { t } = useTranslation();
  const XDefaultRow: IMagnetRow = {
    laneId: 0,
    weaponType: " ",
    numberOfShots: "",
    numberOfHits: "",
    numberOfTargets: "",
    targetsDestroyed: "",
    weapons: [],
    hashtags: "",
    soldierId: "",
    soldierName: "",
    soldiersInLanes: [],
    blueDestroyed: "",
    blueHits: "",
    damagePercentage: "",
  };

  const {
    isSuccess,
    setIsSuccess,
    rows,
    setRows,
    refreshRowsWrap,
    resetRowsDataWrap,
  } = UseDataPosting(XDefaultRow, rowsNumber, trainingTypeId);

  // Columns for each input type
  const columns = [
    {
      ...XDefaultColumn,
      key: "laneId",
      name: t("lane"),
      cellClass: "celClassExtra",
      renderEditCell: undefined,
    },
    {
      ...XDefaultColumn,
      key: "soldierName",
      name: t("soldierName"),
      renderEditCell: SoldiersListEditor,
      cellClass: "celClassExtra",
    },
    {
      ...XDefaultColumn,
      key: "soldierId",
      name: t("militaryId"),
      renderCell: PositiveNumberFormatter,
    },
    {
      ...XDefaultColumn,

      key: "weaponType",
      name: t("weaponType"),
      renderEditCell: DropdownEditor,
      cellClass: "celClassExtra formatterKey(weaponType)",
    },
    {
      ...XDefaultColumn,
      key: "numberOfTargets",
      name: t("numOfTargets"),
      renderCell: (event: any) => (
        <PositiveNumberNtoMFormatter
          minToMax={{ max: 10, min: 1 }}
          event={event}
        />
      ),
    },
    {
      ...XDefaultColumn,
      key: "numberOfShots",
      name: t("numberOfShots"),
      renderCell: PositiveNumberFormatter,
    },

    {
      ...XDefaultColumn,

      key: "numberOfHits",
      name: t("numberOfHits"),
      editorOptions: {
        editOnClick: true,
        editOnFocus: true,
      },

      cellClass: "celClass", // remove left and right padding
      renderCell: (event: any) => (
        <ComparisonFormatter comparisonKey="numberOfShots" event={event} />
      ),
    },
    {
      ...XDefaultColumn,
      key: "targetsDestroyed",
      name: t("numOfDestroyed"),
      renderCell: PositiveNumberFormatter,
    },
    {
      ...XDefaultColumn,
      key: "blueHits",
      name: t("BlueHits"),
      renderCell: PositiveNumberFormatter,
    },
    {
      ...XDefaultColumn,
      key: "blueDestroyed",
      name: t("BlueDestroyed"),
      renderCell: PositiveNumberFormatter,
    },
    {
      ...XDefaultColumn,
      key: "damagePercentage",
      name: t("damagePercentage"),
      renderCell: (event: any) => (
        <PositiveNumberNtoMFormatter
          minToMax={{ max: 100, min: 1 }}
          event={event}
        />
      ),
    },
    {
      ...XDefaultColumn,
      key: "hashtags",
      name: t("hashtagsExcName"),
      cellClass: "celClassExtra",
    },
  ];

  // Manages data sending
  const sendData = async (row: IMagnetRow) => {
    // To avoid sending empty rows, check if all elements exist
    if (row.soldierId !== "") {
      if (row.targetsDestroyed === "" || row.numberOfTargets === "") {
        customToast.error(t("fillMagnetError"));
      } else {
        // Sets the loading
        setIsSuccess(-1);
        let dbLaneId: number | undefined;
        let rowToSend: magnetMessage;
        let weaponType = row.weaponType === " " ? "None" : row.weaponType;
        dbLaneId = await convertLaneToMrkANdIESTLane(row.laneId);
        // If a lane matches, it creates the row to send
        if (dbLaneId) {
          rowToSend = {
            SoldierID: row.soldierId,
            MessageType: messageType,
            TimeStamp: new Date(),
            TrainingTypeID: trainingTypeId,
            LaneID: dbLaneId,
            WeaponType: weaponType,
            NumOfShots: row.numberOfShots,
            RedHits: row.numberOfHits,
            NumOfTargets: row.numberOfTargets,
            TargetsDestroyed: row.targetsDestroyed,
            Hashtags: row.hashtags.split(" "),
            DrillStartTime: new Date(),
            PlanId: props.selectedPlan?.id,
            BlueDestroyed: row.blueDestroyed,
            BlueHits: row.blueHits,
            Damage: row.damagePercentage,
          };
          // Then sends it
          sendDrillToBBackend(
            rowToSend,
            () => {
              setIsSuccess(200);
              props.resetCompetencyForces();
            },
            () => setIsSuccess(500)
          );
        }
      }
    }
  };

  return (
    <DesktopManualInputTemplate
      selectedPlan={props.selectedPlan}
      plansOptions={props.plansOptions}
      onPlanSelectedHandler={props.onPlanSelectedHandler}
      pageName={"MAGNET_Urban"}
      onClickThirdButton={() => {
        handleSave(rows, sendData, t);
      }}
      onClickSecondButton={() => {
        refreshRowsWrap(XDefaultRow);
      }}
      onClickFirstButton={() => {
        resetRowsDataWrap(XDefaultRow);
      }}
    >
      <DrillsDataTable
        columns={columns}
        isSuccess={isSuccess}
        resetRows={() => {
          resetRowsData(setRows, rowsNumber, XDefaultRow);
        }}
        setRows={setRows}
        rows={rows}
        isDataPosting
      />
    </DesktopManualInputTemplate>
  );
};

export default Magnet;
