import type { RenderEditCellProps } from "react-data-grid";
import "./DataHistoryTextEditor.css";
function autoFocusAndSelect(input: HTMLInputElement | null) {
  input?.focus();
  input?.select();
}

export default function DataHistoryTextEditor<
  TRow = any,
  TSummaryRow = unknown
>({
  row,
  column,
  onRowChange,
  onClose,
}: RenderEditCellProps<any, TSummaryRow>) {
  return (
    <input
      disabled={!row.editable}
      className={`pmTextEditor ${!row.editable ? "disableEditor" : ""}`}
      ref={autoFocusAndSelect}
      value={row[column.key as keyof TRow] as unknown as string}
      onChange={(event) => {
        onRowChange({
          ...row,
          [column.key]: event.target.value,
        });
      }}
      onBlur={() => onClose(true)}
    />
  );
}
