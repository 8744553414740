import { FC, useContext } from "react";
import { IonCol, IonGrid, IonRow, IonContent, IonPage } from "@ionic/react";
import "./Stations.css";
import StationButtonComp from "../../../components/Mobile/SiteManagement/StationButtonComp";
import { environment } from "../../../Configurations/consts";
import MobileHeader from "../../../components/Mobile/MobileHeader/MobileHeader";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import EEnvironment from "../../../Enums/EEnvironment";
import OrbatTreeModal from "../../../components/Mobile/OrbatTreeModal/OrbatTreeModal";
import ForcesContext from "../../../context/ForcesContext/forcesContext";
import {
  Magnet_FOF,
  SHOOTING_RANGE_STATION_NAME,
} from "../../../Configurations/shootingRangeConsts";
import usePlans from "../../../components/CustomHooks/usePlans";
import { SystemModeContext } from "../../../context/SystemModeContext/SystemModeContext";
import { EAppMode } from "../../../Enums/EAppMode";
import PlansDropdown from "../../../components/Shared/PlansDropdown/PlansDropdown";
import { StationsContext } from "../../../context/StationsContext/StationsContext";
interface IProps {}
const Stations: FC<IProps> = (props: IProps) => {
  const { plansOptions, selectedPlan, onPlanSelectedHandler } = usePlans();
  const cloudStationsNames = [SHOOTING_RANGE_STATION_NAME, Magnet_FOF];
  const { isForcesTreeOpen, setIsForcesTreeOpen } = useContext(ForcesContext);
  const { appMode } = useContext(SystemModeContext);
  const { stations } = useContext(StationsContext);

  return (
    <IonPage className="contentContainerSBL">
      <IonContent className="contentContainerSBL" scrollY={false}>
        <MobileHeader iconEndSrc={EIconsSrc.MENU_ICON} />
        <IonGrid className="gridContainerSBL">
          {environment === EEnvironment.production &&
          appMode !== EAppMode.HASIFA_TKIFA ? (
            <IonRow className="inputPlanRow">
              <PlansDropdown
                onPlanSelectedHandler={onPlanSelectedHandler}
                plansOptions={plansOptions}
                selectedPlan={selectedPlan}
              ></PlansDropdown>
            </IonRow>
          ) : null}

          <div className="scrollContainerSBL mobileScroll">
            <IonRow className="rowContainerSBL">
              {stations
                .filter((station) => station.isManual)
                ?.map((station, index) =>
                  // If production or staging
                  environment === EEnvironment.production ||
                  environment === EEnvironment.staging ? (
                    // Check if current station name is in cloud stations or staging env
                    cloudStationsNames.includes(station.stationName) ||
                    environment === EEnvironment.staging ? (
                      <IonCol key={station.stationId} className={"buttonCol"}>
                        <StationButtonComp
                          station={station}
                          planId={selectedPlan?.id}
                        ></StationButtonComp>
                      </IonCol>
                    ) : null
                  ) : station.stationName !== SHOOTING_RANGE_STATION_NAME ? (
                    // If we're not in production, just generate all stations
                    <IonCol className={"buttonCol"} key={station.stationId}>
                      <StationButtonComp
                        station={station}
                        planId={selectedPlan?.id}
                      ></StationButtonComp>
                    </IonCol>
                  ) : null
                )}
            </IonRow>
          </div>
          <OrbatTreeModal
            limit={3}
            isOpen={isForcesTreeOpen}
            close={() => setIsForcesTreeOpen(false)}
            mode="secondary"
          />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Stations;
