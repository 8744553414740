// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuPopover {
  --height: fit-content !important;
  --width: min-content;
  min-width: 140px;
  --height: 40px;
}

.popover-wrapper .item {
  display: flex;
  height: 40px !important;
  font-size: 15px !important;
  width: 120px !important;
  --width: fit-content !important;
  justify-content: space-around;
}

.last {
  padding-top: 5px !important;
}

.menuText {
  width: 100%;
  display: flex;
}

.menuTextGeneral {
  width: 100%;
  justify-content: center;
  color: var(--ion-color-fontLight);
}

.planMenuIcon {
  padding-inline-start: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Popover/PopoverMenu.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,oBAAoB;EACpB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,0BAA0B;EAC1B,uBAAuB;EACvB,+BAA+B;EAC/B,6BAA6B;AAC/B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,WAAW;EACX,uBAAuB;EACvB,iCAAiC;AACnC;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".menuPopover {\n  --height: fit-content !important;\n  --width: min-content;\n  min-width: 140px;\n  --height: 40px;\n}\n\n.popover-wrapper .item {\n  display: flex;\n  height: 40px !important;\n  font-size: 15px !important;\n  width: 120px !important;\n  --width: fit-content !important;\n  justify-content: space-around;\n}\n\n.last {\n  padding-top: 5px !important;\n}\n\n.menuText {\n  width: 100%;\n  display: flex;\n}\n\n.menuTextGeneral {\n  width: 100%;\n  justify-content: center;\n  color: var(--ion-color-fontLight);\n}\n\n.planMenuIcon {\n  padding-inline-start: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
