import React, { useContext, useEffect } from "react";
import { IonRouterOutlet, IonSplitPane } from "@ionic/react";
import "./SideMenu.css";
import Menu from "./Menu/Menu";
import OrbatSettings from "../../../pages/Desktop/OrbatSettings/OrbatSettings";
import PerformanceDisplayQCP from "../../../pages/Desktop/PerformanceDisplay/PerformanceDisplayQCP";
import siteManagementTabs from "../../../pages/Desktop/SiteManagment/siteManagementTabs";
import TrainingPlanTabs from "../../../pages/Desktop/TrainingPlan/TrainingPlanTabs";
import AuthRoute from "../AuthRoute/AuthRoute";
import { routeRoles, routes } from "../../../services/routeRoles";
import { Redirect, Route } from "react-router";
import Unauthorized from "../../../pages/Desktop/Unauthorized/Unauthorized";
import DataPosting from "../../../pages/Desktop/DataPosting/DataPosting";
import DataHistoryTabs from "../../../pages/Desktop/DataHistory/DataHistoryTabs";
import ReportGeneratorTabs from "../../../pages/Desktop/ReportGenerator/ReportGeneratorTabs";
import PersonalZone from "../../../pages/Desktop/PersonalZone/PersonalZone";
import ForcesContext from "../../../context/ForcesContext/forcesContext";
import { UserContext } from "../../../context/UserContext/userContext";
import StationView from "../../../pages/Desktop/PerformanceDisplay/PerformanceTabs/StationView/StationView";
import GorillaTabs from "../../../pages/Desktop/Gorilla/GorillaTabs";
import { EAppMode } from "../../../Enums/EAppMode";
import { SystemModeContext } from "../../../context/SystemModeContext/SystemModeContext";

const SideMenu: React.FC = (): JSX.Element => {
  const { setForces } = useContext(ForcesContext);
  const { force, user } = useContext(UserContext);
  const { appMode } = useContext(SystemModeContext);

  useEffect(() => {
    if (force) setForces([force]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [force]);

  const Routes: () => JSX.Element = () => (
    <IonRouterOutlet id="main">
      <Route
        path="/"
        render={() => (
          <Redirect
            to={
              appMode === EAppMode.FIRE_SUPPORT ||
              appMode === EAppMode.HASIFA_TKIFA
                ? routes.dataPosting
                : routes.performance
            }
          />
        )}
        exact
      />
      <Route
        path={`${routes.marketing}/:dynamicPart1?/:dynamicPart2?`}
        render={({ match }) => {
          const { dynamicPart1, dynamicPart2 } = match.params;

          return dynamicPart1 && dynamicPart2 ? (
            <Redirect to={`/${dynamicPart1}/${dynamicPart2}`} />
          ) : dynamicPart1 ? (
            <Redirect to={`/${dynamicPart1}`} />
          ) : (
            <Redirect to={routes.performance} />
          );
        }}
      />
      <AuthRoute
        exact
        path={routes.performance}
        Component={PerformanceDisplayQCP}
        requiredRoles={[
          ...routeRoles.Admins,
          ...routeRoles.Instructors,
          ...routeRoles.Viewers,
        ]}
      ></AuthRoute>
      <AuthRoute
        path={routes.performance}
        Component={PerformanceDisplayQCP}
        requiredRoles={[
          ...routeRoles.Admins,
          ...routeRoles.Instructors,
          ...routeRoles.Viewers,
        ]}
      ></AuthRoute>
      <AuthRoute
        exact
        path={routes.trainingPlan}
        Component={TrainingPlanTabs}
        requiredRoles={[
          ...routeRoles.Admins,
          ...routeRoles.Instructors,
          ...routeRoles.Viewers,
        ]}
      />
      <AuthRoute
        exact
        path={routes.siteManagement}
        Component={siteManagementTabs}
        requiredRoles={[
          ...routeRoles.Admins,
          ...routeRoles.Instructors,
          ...routeRoles.Viewers,
        ]}
      ></AuthRoute>
      <AuthRoute
        path={routes.siteManagement}
        Component={siteManagementTabs}
        requiredRoles={[
          ...routeRoles.Admins,
          ...routeRoles.Instructors,
          ...routeRoles.Viewers,
        ]}
      ></AuthRoute>
      <AuthRoute
        exact
        path={routes.orbatSettings}
        Component={OrbatSettings}
        requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
      ></AuthRoute>
      <AuthRoute
        path={routes.orbatSettings}
        Component={OrbatSettings}
        requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
      ></AuthRoute>
      <AuthRoute
        path={routes.dataPosting}
        Component={DataPosting}
        requiredRoles={[...routeRoles.Admins, ...routeRoles.Instructors]}
      ></AuthRoute>
      <AuthRoute
        path={routes.dataHistory}
        Component={DataHistoryTabs}
        requiredRoles={[
          ...routeRoles.Admins,
          ...routeRoles.Instructors,
          ...routeRoles.Viewers,
        ]}
      ></AuthRoute>
      <AuthRoute
        path={routes.reportGenerator}
        Component={ReportGeneratorTabs}
        requiredRoles={[
          ...routeRoles.Admins,
          ...routeRoles.Instructors,
          ...routeRoles.Viewers,
        ]}
      ></AuthRoute>
      <AuthRoute
        path={routes.personal}
        Component={PersonalZone}
        requiredRoles={
          user.relatedForce
            ? [
                ...routeRoles.Admins,
                ...routeRoles.Instructors,
                ...routeRoles.Viewers,
              ]
            : []
        }
      ></AuthRoute>
      <AuthRoute
        path={routes.unauthorized}
        Component={Unauthorized}
        requiredRoles={[
          ...routeRoles.Admins,
          ...routeRoles.Instructors,
          ...routeRoles.Viewers,
        ]}
      ></AuthRoute>
      <AuthRoute
        path={routes.stationView}
        Component={
          appMode === EAppMode.HASIFA_TKIFA ? GorillaTabs : StationView
        }
        requiredRoles={[
          ...routeRoles.Admins,
          ...routeRoles.Instructors,
          ...routeRoles.Viewers,
        ]}
      ></AuthRoute>
    </IonRouterOutlet>
  );

  const SideMenu: () => JSX.Element = () => (
    <IonSplitPane contentId="main">
      <Menu />
      <Routes />
    </IonSplitPane>
  );

  return (
    <div>
      <SideMenu />
    </div>
  );
};

export default SideMenu;
