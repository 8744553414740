import React, { ReactNode, useContext, useState } from "react";
import { baseUrlPMBackend, environment } from "../../Configurations/consts";
import EEnvironment from "../../Enums/EEnvironment";
import IForceTreeNode from "../../Interfaces/IForceTreeNode";
import IUser from "../../Interfaces/IUser";
import { routeRoles } from "../../services/routeRoles";
import { addRoomIO } from "../../services/socketIo";
import Axios from "../../Axios";
import { SystemModeContext } from "../SystemModeContext/SystemModeContext";
import { EAppMode } from "../../Enums/EAppMode";
import Cookies from "universal-cookie";

interface IUserContext {
  user: IUser;
  setUser: React.Dispatch<React.SetStateAction<IUser>>;
  login: (user: IUser) => void;
  logout: (logoutUnauthorizeUser?: boolean) => void;
  setTaggingMode: () => void;
  isLoggedIn: boolean | "unknown";
  authTp: () => void;
  isTpAuthed: boolean;
  isTaggingActive: boolean;
  isAdmin: boolean;
  isInstructor: boolean;
  force: IForceTreeNode;
  setForce: (force: IForceTreeNode) => void;
  darkMode: boolean;
  setDarkMode: (state: boolean) => void;
  toggleDarkModeHandler: () => void;
  isLoginLoading: boolean;
  setIsLoginLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLocalLogin: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  isLocalLogin: boolean | undefined;
  setUseLockoutTime: React.Dispatch<React.SetStateAction<number | undefined>>;
  userLockoutTime: number | undefined;
}

export const UserContext = React.createContext<IUserContext>({
  setDarkMode: () => {},
  toggleDarkModeHandler: () => {},
  login: () => {},
  setUser: () => {},
  logout: () => {},
  authTp: () => {},
  setTaggingMode: () => {},
  force: {} as IForceTreeNode,
  setForce: () => {},
  user: {
    role: "",
    displayName: "",
    id: -1,
    emailAddress: "",
    relatedForce: undefined,
    forceToDisplayInOrbat: { id: -1, forceType: "" },
  },
  isLoggedIn: false,
  isTpAuthed: false,
  isTaggingActive: JSON.parse(
    String(localStorage.getItem("taggingConnection"))
  ),
  isAdmin: false,
  isInstructor: false,
  darkMode: true,
  setIsLoginLoading: () => {},
  isLoginLoading: true,
  isLocalLogin: false,
  setIsLocalLogin: () => {},
  setUseLockoutTime: () => {},
  userLockoutTime: 0,
});

const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [userLockoutTime, setUseLockoutTime] = useState<number>();

  const [user, setUser] = useState<IUser>({
    role: "",
    displayName: "",
    id: -1,
    emailAddress: "",
    relatedForce: undefined,
    forceToDisplayInOrbat: { id: -1, forceType: "" },
  });
  const { appMode, setAppMode } = useContext(SystemModeContext);

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isTpAuthed, setIsTpAuthed] = useState<boolean>(
    !(
      environment?.toString() === EEnvironment.localSite &&
      appMode === EAppMode.WARTAC
    )
  );
  const [isTaggingActive, setIsTaggingActive] = useState<boolean>(
    JSON.parse(String(localStorage.getItem("taggingConnection")))
  );
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isInstructor, setIsInstructor] = useState<boolean>(false);
  const [force, setForce] = useState<IForceTreeNode>({} as IForceTreeNode);
  const [darkMode, setDarkMode] = useState<boolean>(true);
  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(true);
  const [isLocalLogin, setIsLocalLogin] = useState<boolean>();

  const login = (user: IUser) => {
    setUser(user);
    if (environment?.toString() !== "production") {
      addRoomIO("check-in-out-room");
      addRoomIO("register-room");
      addRoomIO("tagging-room");
    }
    if (user.role === routeRoles.Admins[0]) setIsAdmin(true);
    if (user.role === routeRoles.Instructors[0]) setIsInstructor(true);
    setIsLoggedIn(true);
  };
  /**
   *
   * @param logoutUnauthorizeUser - whether or not to logout the user from the DB
   */
  const logout = () => {
    const cookie = new Cookies();

    cookie.remove("userCredentials");

    const itemsToPreserve = [
      "theme",
      "loginWithMicrosoft",
      "i18nextLng",
      "language",
      "appMode",
    ];
    localStorage.setItem("loginWithMicrosoft", "false");

    for (let i = 0; i < localStorage.length; i++) {
      const key: any = localStorage.key(i);
      if (!itemsToPreserve.includes(key)) {
        localStorage.removeItem(key);
      }
    }
    // if (!logoutUnauthorizeUser) Axios.post(`${baseUrlPMBackend}users/logout`);
    setUser({
      role: "",
      displayName: "",
      id: -1,
      emailAddress: "",
      relatedForce: undefined,
      forceToDisplayInOrbat: { id: -1, forceType: "" },
    });

    setIsAdmin(false);

    Axios.defaults.headers.common["Authorization"] = ``;
    setIsLoginLoading(environment === EEnvironment.production);
    setIsLocalLogin(environment !== EEnvironment.production);
    setIsLoggedIn(false);
  };

  const authTp = () => {
    setIsTpAuthed(true);
  };

  const setTaggingMode = () => {
    localStorage.setItem("taggingConnection", String(!isTaggingActive));
    setIsTaggingActive((prev: boolean) => !prev);
  };

  // Sets theme
  const toggleDarkModeHandler = () => {
    document.body.classList.toggle("dark-theme");
    setDarkMode((prev) => {
      if (prev) localStorage.setItem("theme", "light");
      else localStorage.setItem("theme", "dark");
      return !prev;
    });
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isLoggedIn,
        login,
        logout,
        authTp,
        isTpAuthed,
        isTaggingActive,
        setTaggingMode,
        isAdmin,
        isInstructor,
        force,
        setForce,
        darkMode,
        setDarkMode,
        toggleDarkModeHandler,
        isLoginLoading,
        setIsLoginLoading,
        setIsLocalLogin,
        isLocalLogin,
        setUseLockoutTime,
        userLockoutTime,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
