import { Dispatch, SetStateAction } from "react";
import { TFunction } from "i18next";
import { textEditor } from "react-data-grid";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import IHeat from "../../../Interfaces/IHeat";
import ISoldier from "../../../Interfaces/ISoldier";
import { errorClassName } from "../../Shared/InputWithError/InputWithError";
import customToast from "../../Shared/Toast/CustomToast";
import { mrkLanes } from "../../../mrkLanes";
export interface IbasicRow {
  soldierId: number | "";
  soldierName: string | "";
  laneId?: number;
  weapons: string[];
  soldiersInLanes: ISoldier[];
}

const XDefaultColumn = {
  editorOptions: {
    editOnClick: true,
    editOnFocus: true,
    commitOnOutsideClick: false,
  },
  renderEditCell: textEditor,
  cellClass: "celClass", // remove left and right padding
  resizable: true,
  editable: true,
};

// Creates the rows and fills with data
const initializeRows = <T extends IbasicRow>(
  numberOfRows: number,
  XDefaultRow: T,
  options: T = {} as T,
  prev?: T[],
  isMrk?: boolean,
  soldiersInLanes?: ISoldier[]
): T[] => {
  let rows: T[] = [];

  if (prev) {
    rows = prev.map((row, index) => {
      return {
        ...XDefaultRow,
        laneId: row.laneId,
        soldierId: isMrk
          ? row.soldierId
          : row.soldiersInLanes[index]
          ? row.soldiersInLanes[index].id
          : "",
        soldierName: isMrk
          ? row.soldierName
          : row.soldiersInLanes[index]
          ? row.soldiersInLanes[index].name
          : "",
        weapons: row.weapons,
        soldiersInLanes: row.soldiersInLanes,
        id: index,
      };
    });
  }

  for (let i = 0; i < numberOfRows; i++) {
    // if soldier checked in - insert name and id as defaluts.
    rows[i] = {
      ...XDefaultRow,
      ...options,
      laneId: i + 1,
      soldierId:
        Object.keys(options).length !== 0 && options.soldiersInLanes[i]
          ? options.soldiersInLanes[i].id
          : "",
      soldierName:
        Object.keys(options).length !== 0 && options.soldiersInLanes[i]
          ? options.soldiersInLanes[i].name
          : "",
      id: i,
    };
  }
  if (isMrk && soldiersInLanes)
    return orderMRKAndIESTLanes(soldiersInLanes, rows);
  return rows;
};

const resetRowsData = <T extends IbasicRow>(
  setRows: React.Dispatch<React.SetStateAction<T[]>>,
  rowsNumber: number,
  XDefaultRow: T,
  isMrk?: boolean,
  soldiersInLanes?: ISoldier[],
  options?: any
) => {
  setRows((prev) =>
    initializeRows(
      rowsNumber,
      XDefaultRow,
      { ...options } as T,
      prev,
      isMrk,
      soldiersInLanes
    )
  );
};
const refreshRows = <T extends IbasicRow>(
  setRows: React.Dispatch<React.SetStateAction<T[]>>,
  rowsNumber: number,
  XDefaultRow: T,
  weapons: string[],
  isMrk?: boolean,
  soldiersInLanes?: ISoldier[]
) => {
  setRows((prev) =>
    initializeRows(
      rowsNumber,
      { ...XDefaultRow, weapons: weapons },
      {} as T,
      prev,
      isMrk,
      soldiersInLanes
    )
  );
};

const handleSave = async <T>(rows: T[], sendData: (row: T) => {}, t: any) => {
  if (!document.getElementsByClassName(errorClassName).length) {
    //!mast be cellInputError!!!
    await Promise.all(rows.map(async (row) => sendData(row)));
  } else {
    customToast.error(t("invalidPostData"));
  }
};

const sendDrillToBBackend = (
  drill: any,
  OKHandler?: () => void,
  FailedHandler?: () => void,
  t?: TFunction,
  showToast?: boolean
) => {
  Axios.post(`${baseUrlPMBackend}stations/insertDrillDataToDB`, drill)
    .then(() => {
      showToast && t && customToast.success(t("dataSendSuccess"));
      OKHandler && OKHandler();
    })
    .catch((error) => {
      FailedHandler && FailedHandler();
      console.error(error);
      t && customToast.error(t("dataSendError"));
    });
};

const selectHeat = <T>(
  heat: IHeat,
  setRows: Dispatch<SetStateAction<T[]>>,
  setIsHeatsModalOpen: Dispatch<SetStateAction<boolean>>
) => {
  setRows((prev) =>
    prev.map((row) => {
      return {
        ...row,
        heat: heat.name,
        numberOfShots: Number(heat.bullets),
        hashtags: heat.hashtags,
      };
    })
  );

  setIsHeatsModalOpen(false);
};
const orderMRKAndIESTLanes = (soldiersInLanes: ISoldier[], rows: any[]) => {
  let newArray: any[] = [];
  rows.forEach((row) => {
    let laneId = convertLaneToMrkANdIESTLane(row.laneId);
    if (laneId) {
      let soldier = soldiersInLanes?.find(
        (r) => Number(r.laneId) === Number(laneId)
      );
      row.soldierName = soldier ? soldier.name : "";
      row.soldierId = soldier ? soldier.id : "";
      newArray = [...newArray, row];
    }
  });
  return newArray;
};
// Converts the ui lanes (1->14) to the mrkLane dictionary
const convertLaneToMrkANdIESTLane = (laneId?: number): number | undefined => {
  return mrkLanes.find((mrkLane) => Number(mrkLane.iestId) === Number(laneId))
    ?.siteManagerId;
};
export {
  initializeRows,
  XDefaultColumn,
  resetRowsData,
  refreshRows,
  handleSave,
  sendDrillToBBackend,
  selectHeat,
  orderMRKAndIESTLanes,
  convertLaneToMrkANdIESTLane,
};
