import { useContext, useEffect, useState } from "react";
import { environment } from "../../Configurations/consts";
import EEnvironment from "../../Enums/EEnvironment";
import { EAppMode } from "../../Enums/EAppMode";
import TabItem from "../../Interfaces/ITabItem";
import { SystemModeContext } from "../../context/SystemModeContext/SystemModeContext";
import useNavigation from "./useNavigation";
import { routes } from "../../services/routeRoles";
import { EGorillaTrainings } from "../../Interfaces/Gorilla/GorillaTrainings";
import { TrainingTypesContext } from "../../context/TrainingTypesContext/TrainingTypesContext";

export enum EDataPostingTrainingTypes {
  responsiveShootingIEST = "IEST_Responsive_Shooting",
  responsiveShootingBIST = "BIST_Responsive_Shooting",
  simulativeShootingIEST = "IEST_Simulative_Shooting",
  simulativeShootingBIST = "BIST_Simulative_Shooting",
  BISTUrban = "BIST_Urban",
  medical = "Tourniquet_Placement",
  combatFitness = "Combat_Fitness",
  urbanInstructorFeedback = "Urban_Instructor_Feedback",
  magnetInstructorFeedback = "Magnet_Instructor_Feedback",
  shootingRange = "Shooting_Range",
  magnetFOF = "Magnet_FOF",
}

export enum dataPostingRoutes {
  responsiveShootingIEST = `/dataPosting/${EDataPostingTrainingTypes.responsiveShootingIEST}`,
  responsiveShootingBIST = `/dataPosting/${EDataPostingTrainingTypes.responsiveShootingBIST}`,
  simulativeShootingIEST = `/dataPosting/${EDataPostingTrainingTypes.simulativeShootingIEST}`,
  simulativeShootingBIST = `/dataPosting/${EDataPostingTrainingTypes.simulativeShootingBIST}`,
  BISTUrban = `/dataPosting/${EDataPostingTrainingTypes.BISTUrban}`,
  medical = `/dataPosting/${EDataPostingTrainingTypes.medical}`,
  combatFitness = `/dataPosting/${EDataPostingTrainingTypes.combatFitness}`,
  urbanInstructorFeedback = `/dataPosting/${EDataPostingTrainingTypes.urbanInstructorFeedback}`,
  magnetInstructorFeedback = `/dataPosting/${EDataPostingTrainingTypes.magnetInstructorFeedback}`,
  shootingRange = `/dataPosting/${EDataPostingTrainingTypes.shootingRange}`,
  magnetFOF = `/dataPosting/${EDataPostingTrainingTypes.magnetFOF}`,
  spike = `/dataPosting/${EAppMode.SPIKE}`,
  trainingNotExist = `/dataPosting/trainingNotExist`,
  fireSupport = `/dataPosting/${EAppMode.FIRE_SUPPORT}`,
  hasifaTkifa = `/dataPosting/${EAppMode.HASIFA_TKIFA}`,
}
const UseDataPostingTabs = () => {
  const [tabOptions, setTabsOptions] = useState<TabItem[]>([]);
  const { appMode } = useContext(SystemModeContext);
  const { trainingTypes } = useContext(TrainingTypesContext);
  const { replace } = useNavigation();
  useEffect(() => {
    replace(routes.dataPosting);
    // eslint-disable-next-line
  }, [tabOptions]);
  useEffect(() => {
    const addTabsAccordingToEnvironment = () => {
      let tabs: TabItem[] = [];

      if (environment !== EEnvironment.production)
        tabs = [
          {
            url: dataPostingRoutes.responsiveShootingIEST,
            title: EDataPostingTrainingTypes.responsiveShootingIEST,
          },
          {
            url: dataPostingRoutes.responsiveShootingBIST,
            title: EDataPostingTrainingTypes.responsiveShootingBIST,
          },
          {
            url: dataPostingRoutes.simulativeShootingIEST,
            title: EDataPostingTrainingTypes.simulativeShootingIEST,
          },
          {
            url: dataPostingRoutes.simulativeShootingBIST,
            title: EDataPostingTrainingTypes.simulativeShootingBIST,
          },
          {
            url: dataPostingRoutes.BISTUrban,
            title: EDataPostingTrainingTypes.BISTUrban,
          },
          {
            url: dataPostingRoutes.medical,
            title: EDataPostingTrainingTypes.medical,
          },
          {
            url: dataPostingRoutes.combatFitness,
            title: EDataPostingTrainingTypes.combatFitness,
          },
          {
            url: dataPostingRoutes.urbanInstructorFeedback,
            title: EDataPostingTrainingTypes.urbanInstructorFeedback,
          },
          {
            url: dataPostingRoutes.magnetInstructorFeedback,
            title: EDataPostingTrainingTypes.magnetInstructorFeedback,
          },
        ];

      if (environment !== EEnvironment.localSite) {
        tabs = [
          {
            url: dataPostingRoutes.shootingRange,
            title: EDataPostingTrainingTypes.shootingRange,
          },
          {
            url: dataPostingRoutes.magnetFOF,
            title: EDataPostingTrainingTypes.magnetFOF,
          },
          ...tabs,
        ];
      }

      if (appMode !== EAppMode.WARTAC) {
        tabs = [
          ...tabs,
          {
            url: `/dataPosting/${appMode}`,
            title: appMode,
            trainingType: appMode,
          },
        ];
      }
      if (appMode === EAppMode.HASIFA_TKIFA) {
        tabs = [
          ...tabs,
          {
            url: `/dataPosting/${EAppMode.HASIFA_TKIFA}/${EGorillaTrainings.FrameTraining}`,
            title: EGorillaTrainings.FrameTraining,
            trainingType: EGorillaTrainings.FrameTraining,
          },
          {
            url: `/dataPosting/${EAppMode.HASIFA_TKIFA}/${EGorillaTrainings.SingleGunner}`,
            title: EGorillaTrainings.SingleGunner,
            trainingType: EGorillaTrainings.SingleGunner,
          },
          {
            url: `/dataPosting/${EAppMode.HASIFA_TKIFA}/${EGorillaTrainings.Observer}`,
            title: EGorillaTrainings.Observer,
            trainingType: EGorillaTrainings.Observer,
          },
        ];
      }

      return tabs.filter((trainingType) => {
        return (
          //if the training types in the system mode
          trainingTypes.find((tt) => tt.name === trainingType.title) !==
            undefined ||
          (appMode && appMode === trainingType.trainingType)
        );
      });
    };
    let tabs = addTabsAccordingToEnvironment();

    setTabsOptions(tabs); // Use a new array
    // eslint-disable-next-line
  }, [appMode, environment, appMode, trainingTypes]);

  return { tabOptions };
};
export default UseDataPostingTabs;
