import React, { useContext, useState } from "react";
import { IonRadio, IonRadioGroup } from "@ionic/react";
import { useTranslation } from "react-i18next";
import PMLabel from "../../themeComponents/PMLabel";
import "./SystemModeChange.css";
import { EAppMode } from "../../../Enums/EAppMode";
import { SystemModeContext } from "../../../context/SystemModeContext/SystemModeContext";
import PopoverItem from "../../themeComponents/PopoverItem";
import PopoverMenu from "../Popover/PopoverMenu";
import axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import { UserContext } from "../../../context/UserContext/userContext";

const SystemModePopover: React.FC = () => {
  const { t } = useTranslation();

  const [popoverState, setShowPopover] = useState<{
    showPopover: boolean;
    event: MouseEvent | undefined;
  }>({
    showPopover: false,
    event: undefined,
  });
  return (
    <>
      <PMLabel
        onClick={(e) =>
          setShowPopover({
            showPopover: true,
            event: e.nativeEvent,
          })
        }
        textAlign="center"
        fontColor="light"
        fontFamily="Light"
        fontSize="medium"
        cssClass="modeSelectionWrap"
      >
        {t("modeSelection")}
      </PMLabel>
      <PopoverMenu
        className="systemModesPopover"
        popoverState={popoverState}
        onDismiss={() =>
          setShowPopover({
            showPopover: false,
            event: undefined,
          })
        }
      >
        <SystemModeGroup isPopover />
      </PopoverMenu>
    </>
  );
};
interface IProps {
  isPopover?: boolean;
  onSelectHandler?: () => void;
}
const SystemModeGroup: React.FC<IProps> = (props: IProps) => {
  const { setAppMode, appMode } = useContext(SystemModeContext);

  const { user } = useContext(UserContext);
  const setUserAppMode = async (mode: string) => {
    await axios.post(`${baseUrlPMBackend}users/setUserAppMode`, {
      appMode: mode,
      emailAddress: user.emailAddress,
    });
  };
  const { t } = useTranslation();
  return (
    <IonRadioGroup value={appMode}>
      {Object.keys(EAppMode).map((mode) => (
        <PopoverItem
          key={mode}
          onClickHandler={(e: any) => {
            setAppMode(EAppMode[mode as EAppMode]);
            localStorage.setItem("appMode", mode);
            setUserAppMode(mode);
            props.onSelectHandler && props.onSelectHandler();
          }}
        >
          <PMLabel
            textAlign="center"
            fontColor={appMode === mode ? "orange" : "light"}
            fontFamily={appMode === mode ? "Bold" : "Regular"}
            fontSize="medium"
          >
            {t(mode)}
          </PMLabel>
          {props.isPopover ? (
            <IonRadio
              className="radioSystemMode"
              slot="end"
              value={mode}
            ></IonRadio>
          ) : null}
        </PopoverItem>
      ))}
    </IonRadioGroup>
  );
};

export { SystemModePopover, SystemModeGroup };
