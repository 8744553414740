import {
  IonAccordion,
  IonAccordionGroup,
  IonCol,
  IonGrid,
  IonItem,
  IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import useGetPerformanceData from "../../../components/CustomHooks/UseGetPerformanceData";
import PMLabel from "../../../components/themeComponents/PMLabel";
import { IDateRange } from "../../../Interfaces/IDatePicker";
import { caretDown } from "ionicons/icons";

import "./DataHistoryMobile.css";
import AccordionMobileData from "./AccordionMobileData";
import IData from "../../../Interfaces/IDataHistoryData";
import Spinner from "../../../components/Shared/Spinner/Spinner";
import IForceTreeNode from "../../../Interfaces/IForceTreeNode";
import IForceFromOrbat from "../../../Interfaces/IForceFromOrbat";
import NotExistLabel from "../../../components/Shared/NotExistLabel/NotExistLabel";

type Props = {
  trainingTypeId: number;
  rangeDate: IDateRange | undefined;
  selectedSoldier: IForceTreeNode[];
  setSelectedSoldier: React.Dispatch<React.SetStateAction<IForceTreeNode[]>>;
  forcesToRemove: IForceFromOrbat[];
  newForces: IForceFromOrbat[];
  activePlanId: number | undefined;
};

const shortenDate = (date: string) => {
  let newDate = date.match(/\d+/g);
  if (newDate === null) return date;

  return newDate[0] + "/" + newDate[1] + "/" + newDate[2].substring(2);
};

// return the time in a format of hh/mm
const shortenTimeDisplay = (time: string) => {
  return time.substring(0, 5);
};

const HistoryListMobile = (props: Props) => {
  const { trainingTypeId, rangeDate, selectedSoldier, activePlanId } = props;

  const [filteredSoldierUniqueIds, setFilteredSoldierUniqueIds] =
    useState<number[]>();

  const {
    loading,
    data: drillsSummarizedData,
    setCameToEnd,
  } = useGetPerformanceData(
    trainingTypeId,
    rangeDate,
    filteredSoldierUniqueIds || [],
    activePlanId
  );

  useEffect(() => {
    setFilteredSoldierUniqueIds((prev) => {
      let newIds = selectedSoldier.map((force) => force.id);
      if (
        !prev ||
        JSON.stringify(prev?.sort()) !== JSON.stringify(newIds.sort())
      )
        return newIds;
      return prev;
    });
  }, [selectedSoldier]);

  const handleScroll = (e: any) => {
    if (
      e.target.scrollTop > 0 && //make sure scroll happened
      Math.floor(e.target.scrollHeight - e.target.scrollTop) -
        Math.floor(e.target.clientHeight) <
        2
    ) {
      setCameToEnd((prev) => (prev === false ? true : prev));
    }
  };

  return loading && drillsSummarizedData.length === 0 ? (
    <Spinner className="dh-mobile-spinner" />
  ) : drillsSummarizedData.length > 0 ? (
    <IonRow className="mobile_dh_list_row" onScroll={handleScroll}>
      <IonAccordionGroup className="accordion-list">
        {drillsSummarizedData.map((singleLine: IData, value) => {
          return (
            <IonAccordion
              key={singleLine.drillId}
              value={value.toString()}
              className="accordion-item"
              toggleIcon={caretDown}
            >
              <IonItem slot="header" className="accordion-line">
                <IonGrid>
                  <IonRow>
                    <IonCol size="2.7" class="ion-no-padding">
                      <PMLabel
                        fontColor="xLight"
                        fontFamily="Light"
                        fontSize="medium"
                        textAlign="start"
                      >
                        {shortenDate(singleLine.date)}
                      </PMLabel>
                    </IonCol>
                    <IonCol size="0.4" class="ion-no-padding">
                      <PMLabel
                        fontColor="dark"
                        fontFamily="Light"
                        fontSize="small"
                        textAlign="start"
                      >
                        |
                      </PMLabel>
                    </IonCol>
                    <IonCol size="2.5" class="ion-no-padding">
                      <PMLabel
                        fontColor="xLight"
                        fontFamily="Light"
                        fontSize="medium"
                        textAlign="start"
                      >
                        {shortenTimeDisplay(singleLine.hour)}
                      </PMLabel>
                    </IonCol>
                    <IonCol size="5" class="ion-no-padding">
                      <PMLabel
                        fontColor="xLight"
                        fontFamily="Light"
                        fontSize="medium"
                        textAlign="start"
                      >
                        {singleLine.soldierName}
                      </PMLabel>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
              <div slot="content">
                <AccordionMobileData data={singleLine} />
              </div>
            </IonAccordion>
          );
        })}
      </IonAccordionGroup>
      {loading ? <Spinner className="dh-mobile-spinner-bottom" /> : <></>}
    </IonRow>
  ) : (
    <NotExistLabel
      text="noForceDataInDate"
      className="no-selected-forces-in-date"
    />
  );
};

export default HistoryListMobile;
