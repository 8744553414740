// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectDropdown {
  background-color: var(--ion-color-medium);
  color: var(--ion-color-fontXLight);
  width: 100%;
  height: 100%;
  border: none !important;
  border-color: transparent;
  padding-inline-start: 5px;
}
.selectDropdown::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.dropdownDiv {
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/DataPosting/DropdownEditor.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,kCAAkC;EAClC,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,yBAAyB;EACzB,yBAAyB;AAC3B;AACA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".selectDropdown {\n  background-color: var(--ion-color-medium);\n  color: var(--ion-color-fontXLight);\n  width: 100%;\n  height: 100%;\n  border: none !important;\n  border-color: transparent;\n  padding-inline-start: 5px;\n}\n.selectDropdown::-webkit-scrollbar {\n  width: 7px;\n  height: 7px;\n}\n\n.dropdownDiv {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
