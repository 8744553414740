import React from "react";
import "./SimpleSelect.css";
interface IProps {
  onSelect: (state: any) => void;
  options: { key: string | number; value: string | number }[];
  title: string;
  value: string | number;
}
const SimpleSelect: React.FC<IProps> = (props: IProps) => {
  const { onSelect, options, title, value } = props;

  return (
    <select
      defaultValue={title}
      value={value === "" ? title : value}
      onChange={(e) => {
        onSelect(e);
      }}
      autoFocus
      className="simpleSelectDropdown"
    >
      <option disabled defaultValue={title}>
        {title}
      </option>
      {options.map((option) => (
        <option key={option.key} value={option.value}>
          {option.value}
        </option>
      ))}
    </select>
  );
};

export default SimpleSelect;
