import React from "react";
import { useTranslation } from "react-i18next";
import { InputWithError } from "../../Shared/InputWithError/InputWithError";
import {
  comparison,
  positiveNumber,
  positiveNumberNtoM,
} from "./inputValidations";
interface IComparisonFormatter {
  event: any;
  comparisonKey: string;
  sumKeys?: string[];
}

const ComparisonFormatter: React.FC<IComparisonFormatter> = (
  props: IComparisonFormatter
): JSX.Element => {
  const { t } = useTranslation();
  const { comparisonKey, event, sumKeys } = props;

  return (
    <div className="cellDClassWrap">
      {comparison(
        sumKeys?.length
          ? [...sumKeys, event.column?.key].reduce(
              (sum, key) => sum + Number(event.row[key]),
              0
            )
          : event.row[event.column?.key],
        event.row[comparisonKey],
        true
      ) !== "" && event.row[event.column?.key]?.length ? (
        <InputWithError
          text={event.row[event.column.key]}
          error={
            comparison(
              sumKeys?.length
                ? [...sumKeys, event.column?.key].reduce(
                    (sum, key) => sum + Number(event.row[key]),
                    0
                  )
                : event.row[event.column?.key],
              event.row[comparisonKey],
              true
            ) !== "" && !isNaN(Number(event.row[event.column?.key]))
              ? event.row[event.column?.key] < 0
                ? t("positiveNumbersOnly")
                : `${t(comparisonKey)} < ${
                    sumKeys?.length
                      ? [...sumKeys, event.column?.key]
                          .map((v) => getInitials(t(v)) || "")
                          .join(" + ")
                      : t(event.column?.key)
                  }`
              : t("positiveNumbersOnly")
          }
        ></InputWithError>
      ) : (
        <div className={`cellDClass formatterKey(${event.column?.key}) `}>
          {event.row[event.column?.key]}
        </div>
      )}
    </div>
  );
};

const PositiveNumberFormatter: React.FC = (event: any): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="cellDClassWrap">
      {positiveNumber(event.row[event.column.key]) !== "" ? (
        <InputWithError
          text={event.row[event.column.key]}
          error={t("positiveNumbersOnly")}
        ></InputWithError>
      ) : (
        <div className={`cellDClass formatterKey(${event.column.key}) `}>
          {event.row[event.column.key]}
        </div>
      )}
    </div>
  );
};
interface IPositiveNumberNtoMFormatter {
  event: any;
  minToMax: { min: number; max: number };
}
const PositiveNumberNtoMFormatter: React.FC<IPositiveNumberNtoMFormatter> = (
  props: IPositiveNumberNtoMFormatter
): JSX.Element => {
  const { t } = useTranslation();
  const { minToMax, event } = props;
  return (
    <div className="cellDClassWrap">
      {positiveNumberNtoM(
        event.row[event.column.key],
        minToMax.max,
        minToMax.min
      ) !== "" ? (
        <InputWithError
          text={event.row[event.column.key]}
          error={
            positiveNumberNtoM(
              event.row[event.column.key],
              minToMax.max,
              minToMax.min
            ) !== "" &&
            !isNaN(event.row[event.column.key]) &&
            minToMax.min !== 0
              ? `${t("mustBeBetween")} ${minToMax.min} ${t("to")} ${
                  minToMax.max
                }`
              : `${t("positiveNumbersOnly")} < = ${minToMax.max}`
          }
        ></InputWithError>
      ) : (
        <div className={`cellDClass formatterKey(${event.column.key}) `}>
          {event.row[event.column.key]}
        </div>
      )}
    </div>
  );
};

export {
  ComparisonFormatter,
  PositiveNumberFormatter,
  PositiveNumberNtoMFormatter,
};

const getInitials = (str: string): string => {
  const words = str.split(" ");
  const initials = words.map((word) => word[0].toUpperCase()).join(". ");
  return initials;
};
