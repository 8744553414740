import { useContext, useEffect, useState } from "react";
import Axios from "../../Axios";
import { baseUrlPMBackend } from "../../Configurations/consts";
import { IDateRange } from "../../Interfaces/IDatePicker";

import { UserContext } from "../../context/UserContext/userContext";
import IData from "../../Interfaces/IDataHistoryData";
import { userRoles } from "../../services/routeRoles";
import { useTranslation } from "react-i18next";
import { getForceFullName } from "../../services/helpers";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
interface Page {
  data: IData[];
  nextCursor?: number;
  previousCursor?: number;
}
const useGetPerformanceData = (
  trainingTypeId: number,
  rangeDate: IDateRange | undefined,
  selectedForcesIds: number[],
  selectedPlanId: undefined | number
) => {
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const isTheUserIsATrainee =
    user.relatedForce?.is_soldier === true && //if the current force is a trainee/soldier
    user.role === userRoles.Viewer && //if the role permission is viewer
    user.relatedForce.id === user.forceToDisplayInOrbat?.id; //if there is only one related force (might be string == number)
  const initLimit = 150;
  const [cameToEnd, setCameToEnd] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataToDisplay, setDataToDisplay] = useState<IData[]>([]);

  const fetchItems = async (_options: any) => {
    //fetch the data history data from db
    const res: any = await Axios.get(
      `${baseUrlPMBackend}dataHistory/getDataHistory`,
      {
        params: {
          trainingTypeId: trainingTypeId,
          forcesIds: selectedForcesIds?.length
            ? selectedForcesIds
            : user.forceToDisplayInOrbat
            ? [user.forceToDisplayInOrbat.id]
            : undefined,
          limit: initLimit,
          offset: data?.pages.reduce((a, b) => a + b.data?.length || 0, 0),
          startDate: isTheUserIsATrainee ? undefined : rangeDate?.startDate,
          endDate: isTheUserIsATrainee ? undefined : rangeDate?.endDate,
          selectedPlanId: selectedPlanId,
        },
      }
    );

    return {
      data: res.data.performanceData.map((row: IData, index: any) => {
        let date = new Date(row.date);
        Object.keys(row).forEach((key) => {
          if (isNaN(row[key])) row[key] = t(row[key]);
        });
        return {
          ...row,
          hour: date.toLocaleTimeString("en-US", {
            hourCycle: "h23",
          }),
          date: `${String(date.getDate()).padStart(2, "0")}/${String(
            date.getMonth() + 1
          ).padStart(2, "0")}/${date.getFullYear()}`,
          soldierName: getForceFullName(row.soldierName, row.forceType, t),
        };
      }),
      previousCursor: undefined,
      nextCursor: 2,
    };
  };
  //use react query infinity scroll to menage the data fetch from the backend
  const {
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isLoading,
    error,
  } = useInfiniteQuery<Page, Error>({
    queryKey: [
      "dataHistoryInfiniteScroll",
      selectedPlanId,
      trainingTypeId,
      selectedForcesIds,
      rangeDate?.startDate,
      rangeDate?.endDate,
      user.forceToDisplayInOrbat?.id,
    ],
    queryFn: fetchItems,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.nextCursor;
    },
    getPreviousPageParam: (firstPage) => {
      return firstPage.previousCursor;
    },
    staleTime: Infinity,
  });
  //if the user scroll to the bottom fetch more data
  useEffect(() => {
    if (cameToEnd && hasNextPage) {
      setCameToEnd(false);
      fetchNextPage();
    }
  }, [cameToEnd]);

  const getCachedData = async () => {
    //get the cached data for all stations
    const cachedData: any = await queryClient.getQueryData([
      "dataHistoryInfiniteScroll",
      selectedPlanId,
      0,
      selectedForcesIds,
      rangeDate?.startDate,
      rangeDate?.endDate,
      user.forceToDisplayInOrbat?.id,
    ]);

    // Filter the cached data based on training type id
    if (cachedData && !data?.pages.map((page) => page.data).flat().length) {
      let dataFromAll = cachedData.pages
        .map((page: any) => page?.data)
        .flat(1)
        .filter(
          (row: IData) =>
            +row.trainingTypeId === +trainingTypeId || trainingTypeId === 0
        );
      setDataToDisplay(
        dataFromAll.map((row: IData, index: number) => ({
          ...row,
          rowIndex: index + 1,
        }))
      );
    }

    return [];
  };
  useEffect(() => {
    if (
      trainingTypeId !== 0 &&
      !data?.pages.map((page) => page.data).flat().length
    ) {
      //for training type id that not all stations and data is not presented
      // get the cached data from all trainings tab
      getCachedData();
    } else {
      //set the dataToDisplay to hold the current station data
      setDataToDisplay(
        data?.pages
          ?.map((page) => page.data)
          .flat()
          .map((row, index) => ({ ...row, rowIndex: index + 1 })) || []
      );
    }
  }, [trainingTypeId, data]);

  return {
    loading: isFetchingNextPage || isLoading || loading,
    error,
    data: dataToDisplay,
    hasMore: hasNextPage,
    setData: setDataToDisplay,
    setLoading,
    setCameToEnd,
    cameToEnd,
  };
};
export default useGetPerformanceData;
