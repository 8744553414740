import React from "react";
import "./DropdownEditor.css";

const DropdownEditor: React.FC<{
  row: any;
  onRowChange: any;
  column: any;
}> = (props: { row: any; onRowChange: any; column: any }) => {
  let [options, inputName] = [[], props.column.key];
  const isWeaponTypes =
    props.column.key === "weaponType" || props.column.key === "WeaponType";
  if (props.column.key === "soldierName") options = props.row.soldiersInLanes;
  else if (isWeaponTypes) options = props.row.weapons;
  else options = props.row.heats;

  return (
    <div className="dropdownDiv">
      <select
        value={props.row[props.column.key]}
        onChange={(event) => {
          let soldier;
          if (props.row.soldiersInLanes) {
            soldier = props.row.soldiersInLanes[event.target.selectedIndex];
          }
          let newRow = {
            ...props.row,
            [props.column.key]: event.target.value,
          };

          if (inputName === "soldierName")
            if (soldier) {
              if (soldier.name !== "") {
                newRow["soldierId"] = soldier.soldierId;
              } else {
                newRow["soldierId"] = "";
              }
            } else {
              newRow["soldierId"] = "";
            }
          props.onRowChange(newRow);
        }}
        autoFocus
        className={`selectDropdown formatterKey(${props.column?.key})`}
      >
        {options?.map((option: any, index: number) => (
          <option
            key={
              props.column.key === "soldierName"
                ? option.soldierId
                : isWeaponTypes
                ? index
                : option.id
            }
            value={
              props.column.key === "soldierName"
                ? option.name
                : isWeaponTypes
                ? option
                : option.name
            }
          >
            {props.column.key === "soldierName"
              ? option.name
              : isWeaponTypes
              ? option
              : option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropdownEditor;
