// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchGridIF {
  height: 100%;
  width: 100%;
  padding: 0;
}
.searchRow {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.searchColUIF {
  height: 95%;
  padding: 0;
}
.col2UIF {
  padding: 5px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.segmentRow .one-segment,
.segmentRow .all-segments {
  min-width: 100px;
  max-height: 30px !important;
  min-height: 30px !important;
}
.segmentRow .one-segment {
  width: 100px;
  max-width: 100px;
}
.FTDContainer .all-segments {
  justify-content: flex-start !important;
}
.segmentRow {
  height: 30px;
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
}
.instructorFeedbackSpinner {
  align-self: center;
  justify-self: center;
}
.instructorFeedbackGrid .segmentRow {
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/DataPosting/InstructorFeedback.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,UAAU;AACZ;AACA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,UAAU;AACZ;AACA;EACE,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;AACA;;EAEE,gBAAgB;EAChB,2BAA2B;EAC3B,2BAA2B;AAC7B;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,sCAAsC;AACxC;AACA;EACE,YAAY;EACZ,aAAa;EACb,2BAA2B;EAC3B,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".searchGridIF {\n  height: 100%;\n  width: 100%;\n  padding: 0;\n}\n.searchRow {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n.searchColUIF {\n  height: 95%;\n  padding: 0;\n}\n.col2UIF {\n  padding: 5px;\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.segmentRow .one-segment,\n.segmentRow .all-segments {\n  min-width: 100px;\n  max-height: 30px !important;\n  min-height: 30px !important;\n}\n.segmentRow .one-segment {\n  width: 100px;\n  max-width: 100px;\n}\n.FTDContainer .all-segments {\n  justify-content: flex-start !important;\n}\n.segmentRow {\n  height: 30px;\n  display: flex;\n  justify-content: flex-start;\n  margin-top: 5px;\n}\n.instructorFeedbackSpinner {\n  align-self: center;\n  justify-self: center;\n}\n.instructorFeedbackGrid .segmentRow {\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
