import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import { TrainingTypesContext } from "../../../context/TrainingTypesContext/TrainingTypesContext";
import ITrainingType from "../../../Interfaces/ITrainingType";
import customToast from "../../Shared/Toast/CustomToast";
import { SystemModeContext } from "../../../context/SystemModeContext/SystemModeContext";

const asyncFetchTrainingTypes = async () => {
  const res = await Axios.get(
    `${baseUrlPMBackend}trainingTypes/getTrainingTypes`
  );
  return res.data;
};

export const useTrainingTypes = () => {
  const { t } = useTranslation();
  const { setTrainingTypes } = useContext(TrainingTypesContext);
  const { appMode } = useContext(SystemModeContext);

  let query = useQuery<ITrainingType[]>({
    queryKey: ["trainingTypes", appMode],
    queryFn: asyncFetchTrainingTypes,
    enabled: appMode !== undefined,
    staleTime: Infinity,
  });
  const { data, isLoading, isError, refetch } = query;

  useEffect(() => {
    //when the app mode change fetch the app indicators
    refetch();
  }, [appMode]);

  useEffect(() => {
    if (!isLoading && !isError && data && data.length) {
      //det the context with the force types data
      setTrainingTypes(
        data.map((trainingType) => ({
          ...trainingType,
          name: trainingType.name,
          id: Number(trainingType.id),
          indicatorId: Number(trainingType.indicatorId),
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (isError) {
      customToast.error(t("errorWhileLoadingTrainingTypes"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
};
