import React, { FC, Fragment } from "react";
import { IonButton, isPlatform } from "@ionic/react";
import IStationDetails from "../../../Interfaces/IStationDetails";
import PMLabel from "../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import { URLs } from "../../../Configurations/urls";
import "./StationButtonComp.css";
import { mobileRoutes } from "../../../services/routeRoles";
import {
  EGorillaTrainings,
  gorillaStations,
} from "../../../Interfaces/Gorilla/GorillaTrainings";
import { EAppMode } from "../../../Enums/EAppMode";

interface IButtonCompData {
  station: IStationDetails;
  planId: number | undefined;
}
const StationButtonComp: FC<IButtonCompData> = (props: IButtonCompData) => {
  const { planId, station } = props;
  const { t } = useTranslation();

  return (
    <Fragment>
      <IonButton
        className="stationButtonComp"
        strong={false}
        routerLink={
          station.stationName.toLocaleLowerCase() ===
            EAppMode.FIRE_SUPPORT.toLocaleLowerCase() && isPlatform("tablet")
            ? mobileRoutes.fireSupport
            : station.stationName === "Shooting_Range"
            ? URLs.stations.shootingRange.plans.getURL()
            : gorillaStations.includes(station.stationName as EGorillaTrainings)
            ? `${mobileRoutes.gorillaInitTraining}/${station.stationName}`
            : `searchSoldier/${planId}/${station.stationName}/${station.stationId}/${station.trainingTypeId}`
        }
        routerDirection="none"
        color="dark"
      >
        <PMLabel
          fontColor="light"
          fontFamily="Regular"
          fontSize="medium"
          textAlign="center"
          cssClass="stationButtonLabel"
        >
          {t(station.stationName.trim())}
        </PMLabel>
      </IonButton>
    </Fragment>
  );
};
export default StationButtonComp;
