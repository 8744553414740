import { IonContent, IonList, IonPopover } from "@ionic/react";
import React from "react";
import "./PopoverMenu.css";

interface IPopoverPros {
  children: React.ReactNode;
  popoverState: { showPopover: boolean; event: undefined | MouseEvent };
  onDismiss: () => void;
  className?: string;
}

const PopoverMenu = (props: IPopoverPros) => {
  return (
    <IonPopover
      className={`menuPopover ${props.className}`}
      event={props.popoverState.event}
      isOpen={props.popoverState.showPopover}
      onDidDismiss={props.onDismiss}
      alignment="start"
      showBackdrop={false}
    >
      <IonContent>
        <IonList className="ion-no-padding popoverList" lines="none">
          {props.children}
        </IonList>
      </IonContent>
    </IonPopover>
  );
};

export default PopoverMenu;
