// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainViewMD {
  height: 80%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.dataPostingPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.buttonsRowMD {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.gridMD {
  height: 60%;
  overflow-y: hidden;
  --ion-grid-padding: var(--pm_padding_l);
}
.dataPostingHeader {
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--pm_padding_m);
  margin-inline-start: 5px;
}
.dataPostingHeaderLabel {
  align-self: flex-start;
}
.compMD {
  width: 100%;
}
.rowDMTHeader {
  height: 100%;
  display: flex;
  flex-direction: row;
}
.refreshButton {
  margin-inline-start: 12px;
  margin-inline-end: 12px;
}
.plt-tablet .dataPostingTemplate {
  margin-top: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/DataPosting/DesktopManualInputTemplate.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;AACA;EACE,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,uCAAuC;AACzC;AACA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kCAAkC;EAClC,wBAAwB;AAC1B;AACA;EACE,sBAAsB;AACxB;AACA;EACE,WAAW;AACb;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,uBAAuB;AACzB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".mainViewMD {\n  height: 80%;\n  overflow-y: auto;\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n}\n\n.dataPostingPage {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n.buttonsRowMD {\n  height: 50px;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n.gridMD {\n  height: 60%;\n  overflow-y: hidden;\n  --ion-grid-padding: var(--pm_padding_l);\n}\n.dataPostingHeader {\n  height: 55px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: var(--pm_padding_m);\n  margin-inline-start: 5px;\n}\n.dataPostingHeaderLabel {\n  align-self: flex-start;\n}\n.compMD {\n  width: 100%;\n}\n.rowDMTHeader {\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n}\n.refreshButton {\n  margin-inline-start: 12px;\n  margin-inline-end: 12px;\n}\n.plt-tablet .dataPostingTemplate {\n  margin-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
