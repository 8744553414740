import React, { useEffect, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./FinalGradeDisplay.css";
import IFinalGradeDisplay from "../../../Interfaces/IFinalGradeDisplay";

const FinalGradeDisplay: React.FC<IFinalGradeDisplay> = (
  props: IFinalGradeDisplay
) => {
  // the color need to be change in run time that why its here
  const [colorClassName, setColor] = useState("classColorGreen");

  const setGradeColor = () => {
    if (Math.round(props.soldierFinalGrade.grade) < props.threshold)
      setColor("classColorYellow");
    else setColor("classColorGreen");
  };
  useEffect(() => {
    setGradeColor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setGradeColor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  return (
    <div
      className={
        "finalGradeDisplayContainer " +
        `${props.className ? props.className : ""}`
      }
    >
      <div className="csvBadgeImage">
        <CircularProgressbarWithChildren
          className={`backgroundProgressBar`}
          value={100}
          strokeWidth={5}
          styles={{
            root: {
              filter: " drop-shadow(0 0 0.3rem black)",
            },
          }}
        >
          <CircularProgressbarWithChildren
            className={`thresholdProgressbar `}
            value={props.threshold}
            strokeWidth={props.soldierFinalGrade.isAttended ? 5 : 0}
            styles={{
              ...buildStyles({
                strokeLinecap: "butt",
              }),
            }}
          >
            <CircularProgressbarWithChildren
              className={`thresholdProgressbarValue`}
              value={props.threshold - 0.7}
              strokeWidth={props.soldierFinalGrade.isAttended ? 5 : 0}
              styles={{
                ...buildStyles({
                  strokeLinecap: "butt",
                }),
              }}
            >
              <CircularProgressbarWithChildren
                className={`finalGradeDisplay ${colorClassName}`}
                value={props.soldierFinalGrade.grade}
                strokeWidth={5}
              >
                <span
                  className={`gradeText  
              ${
                props.soldierFinalGrade.isAttended === false
                  ? "gradeTextRed"
                  : ""
              } ${props.isMobile ? "mobileGradeText" : ""}`}
                >{`${Math.round(props.soldierFinalGrade.grade)}`}</span>
              </CircularProgressbarWithChildren>
            </CircularProgressbarWithChildren>
          </CircularProgressbarWithChildren>
        </CircularProgressbarWithChildren>
      </div>
      {!props.isModal && props.isLegend !== false ? (
        <div className="legendContainer">
          <canvas
            className="legendStyle"
            style={{ backgroundColor: props.soldierFinalGrade.legendColor }}
          />
          <p className="legendTextStyle">{props.soldierFinalGrade.name}</p>
        </div>
      ) : null}
    </div>
  );
};

export default FinalGradeDisplay;
