// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.borderDivider {
  height: 5px;
  position: relative;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 20px;
}
.borderDivider:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: var(--pm_spike_gorilla);
  right: var(--pm_spike_gorilla);
  border-width: 0;
  border-bottom-width: 1px;
  border-color: var(--ion-color-step-400);
  border-style: dotted;
}
.fullDivider.borderDivider:before {
  left: 0 !important;
  right: 0 !important;
}
.solidDivider:before {
  border-style: solid;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SPIKEGorilla/Divider.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,6BAA6B;EAC7B,8BAA8B;EAC9B,eAAe;EACf,wBAAwB;EACxB,uCAAuC;EACvC,oBAAoB;AACtB;AACA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".borderDivider {\n  height: 5px;\n  position: relative;\n  width: 100%;\n  margin-top: 5px;\n  margin-bottom: 20px;\n}\n.borderDivider:before {\n  content: \"\";\n  position: absolute;\n  bottom: 0;\n  left: var(--pm_spike_gorilla);\n  right: var(--pm_spike_gorilla);\n  border-width: 0;\n  border-bottom-width: 1px;\n  border-color: var(--ion-color-step-400);\n  border-style: dotted;\n}\n.fullDivider.borderDivider:before {\n  left: 0 !important;\n  right: 0 !important;\n}\n.solidDivider:before {\n  border-style: solid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
