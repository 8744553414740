import { FC, useContext, useEffect, useState } from "react";
import "./RadarGraph.css";
import { Radar } from "react-chartjs-2";
import IChartData from "../../../../Interfaces/IChartData";
import IToolTipChartItem from "../../../../Interfaces/IToolTipChartItem";
import IRadarData from "../../../../Interfaces/IRadarData";
import IForceFromOrbat from "../../../../Interfaces/IForceFromOrbat";
import IRadarInfo from "../../../../Interfaces/IRadarInfo";
import IChartGrade from "../../../../Interfaces/IChartGrade";
import ILabel from "../../../../Interfaces/ILabel";
import IThreshold from "../../../../Interfaces/IThreshold";
import IDatasetsChart from "../../../../Interfaces/IDatasetsChart";
import { useTranslation } from "react-i18next";
import NotFoundPlan from "../../../../pages/Desktop/NotFound/NotFoundPlan";
import IPlan from "../../../../Interfaces/IPlan";
import { sortByLabelId } from "../../../../services/helpers";
import { IIndicatorResult } from "../../../../Interfaces/dataCalculator";
import Spinner from "../../Spinner/Spinner";
import { AppContext } from "../../../../context/AppContext/AppContext";
import ELanguage from "../../../../Enums/ELanguage";

interface IProps {
  selectedForces: (IForceFromOrbat | undefined)[] | undefined;
  radarData: IRadarInfo[] | undefined;
  thresholds: IThreshold[] | undefined;
  labels: ILabel[] | undefined;
  newForces: (IForceFromOrbat | undefined)[] | undefined;
  forcesToRemove: IForceFromOrbat[] | undefined;
  id?: string;
  className?: string;
  setDataForMobileLabels?: (data: IDatasetsChart[]) => void;
  isModal?: boolean;
  color?: string;
  planId?: number | undefined;
  plan?: IPlan | undefined;
  hidden?: boolean;
}
const RadarGraph: FC<IProps> = (props: IProps): JSX.Element => {
  const { isAsDesktop } = useContext(AppContext);
  const isMobile: boolean = !isAsDesktop;

  const { t, i18n } = useTranslation();
  const {
    selectedForces,
    radarData,
    thresholds,
    labels,
    newForces,
    forcesToRemove,
    setDataForMobileLabels,
    isModal,
    color,
    planId,
    plan,
  } = props;

  const options = {
    legend: {
      position: "bottom",
      responsive: true,
      reverse: false,
      rtl: i18n.language === ELanguage.he,
      display: !isMobile,
      labels: {
        boxWidth: 7,
        fontSize: isModal === true ? 14 : 20,
        fontColor: "rgba(255, 255, 255, 0.89)",
        padding: 10,
        usePointStyle: true,
      },
    },
    gridLines: {
      display: true,
    },
    scale: {
      gridLines: {
        color: "rgba(255,255,255,0.5)",
      },
      angleLines: {
        color: "rgba(255,255,255,0.5)",
      },
      pointLabels: {
        fontSize: isMobile ? 15 : 20,
        fontColor: "rgba(255, 255, 255, 0.89)",
        padding: 100,
      },
      animation: {
        animateScale: true,
        animateRoute: true,
      },
      ticks: {
        showLabelBackdrop: !isMobile,
        fontColor: "#FFFF",
        backdropColor: "#4B4D52",
        fontSize: isMobile ? 8 : isModal === true ? 10 : 12,
        beginAtZero: true,
        suggestedMax: 100,
        maxTicksLimit: 11,
        stepSize: 10,
        backdropPaddingY: 3,
        backdropPaddingX: 4,
      },
    },
    tooltips: {
      backgroundColor: "#36383E",
      titleFontColor: "rgba(255,255,255,0.89)",
      titleMarginBottom: 6,
      bodyFontFamily: "Light",
      bodyFontColor: "rgba(255,255,255,0.89)",
      xPadding: 10,
      caretPadding: 3,
      caretSize: 5,
      cornerRadius: 10,
      displayColors: false,
      titleFontFamily: "Light",
      titleFontSize: 16,
      titleAlign: "right",
      bodyAlign: "right",
      callbacks: {
        title: (tooltipItem: IToolTipChartItem[], data: IChartData) =>
          data.labels ? data.labels[tooltipItem[0].index] : null,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [grades, setGrades] = useState<IChartGrade[]>();
  const [data, setData] = useState<IChartData>({
    labels: [],
    datasets: [],
  });
  let allGrades: IChartGrade[] = [];

  useEffect(() => {
    let timer = setTimeout(() => setLoading(false), 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (isMobile && setDataForMobileLabels) {
      setDataForMobileLabels([...data.datasets]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const initializeThresholdAndLabels = () => {
    let newData: IChartData = data;
    let sortedThresholds = sortByLabelId(thresholds, labels);
    sortedThresholds = sortedThresholds.filter((element: any) =>
      labels?.find((label) => Number(label.id) === Number(element.id))
    );
    newData["labels"] = labels?.map((labelItem: ILabel) => t(labelItem.label));
    newData.datasets[0] = {
      id: 0,
      label: t("thresholdForOperationalIndicators"),
      borderColor: "rgb(0,176,117)",
      pointBackgroundColor: "rgb(0, 176, 117)",
      pointHoverBackgroundColor: "transparent",
      pointHoverBorderColor: "transparent",
      data: sortedThresholds?.map(
        (thresholdItem: IThreshold) => thresholdItem.threshold
      ),
      borderDash: [5, 3],
      fill: false,
    };
    return newData;
  };

  useEffect(() => {
    if (thresholds && labels) {
      setData(() => initializeThresholdAndLabels());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thresholds, labels]);

  useEffect(() => {
    if (newForces?.length && grades?.length) {
      if (!checkIsForceExist(newForces, data.datasets)) {
        if (isMobile) {
          setData((prev: IChartData) => {
            let firstItem = prev.datasets[0]
              ? [prev.datasets[0]]
              : initializeThresholdAndLabels().datasets;

            let d: IChartData = { labels: prev.labels, datasets: firstItem };
            newForces.map((newForce) =>
              d.datasets.push({
                id: newForce!.id,
                label: newForce!.name,
                backgroundColor: "rgba(205,205,205,0.2)",
                borderColor: newForce!.color,
                pointBackgroundColor: newForce!.color,
                pointBorderColor: "transparent",
                pointHoverBackgroundColor: "transparent",
                pointHoverBorderColor: "transparent",
                data: grades
                  .find(
                    (currentGrade) =>
                      Number(currentGrade.id) === Number(newForce!.id)
                  )
                  ?.grades?.map((grade) => Math.round(grade)),
              })
            );
            return { ...prev, datasets: d.datasets };
          });
        } else {
          setData((prev: IChartData) => {
            let datasets = prev.datasets;
            if (!prev.datasets.length) {
              let firstItem = prev.datasets[0]
                ? [prev.datasets[0]]
                : initializeThresholdAndLabels().datasets;
              datasets.push(firstItem[0]);
            }

            newForces.forEach((newForce) => {
              if (newForce)
                datasets.push({
                  id: newForce!.id,
                  label: newForce!.name,
                  backgroundColor: "rgba(205,205,205,0.2)",
                  borderColor:
                    isModal === true && color ? color : newForce!.color,
                  pointBackgroundColor:
                    isModal === true && color ? color : newForce!.color,
                  pointBorderColor: "transparent",
                  pointHoverBackgroundColor: "transparent",
                  pointHoverBorderColor: "transparent",
                  data: grades
                    .find(
                      (currentGrade) =>
                        Number(currentGrade.id) === Number(newForce!.id)
                    )
                    ?.grades?.map((grade) => Math.round(grade)),
                });
            });
            return { ...prev, datasets: datasets };
          });
        }
      } else {
        setData((prev: IChartData) => {
          let datasets = prev.datasets;
          selectedForces?.map((newForce: IForceFromOrbat | undefined) => {
            let index = 0;
            let currentForceInDataset: IDatasetsChart | undefined =
              datasets.find((datasetItem, datasetIndex) => {
                index = datasetIndex;
                return datasetItem.id === newForce!.id;
              });
            if (currentForceInDataset) {
              datasets.splice(index, 1);
              datasets.push({
                id: newForce!.id,
                label: newForce!.name,
                backgroundColor: "rgba(205,205,205,0.2)",
                borderColor: currentForceInDataset?.borderColor,
                pointBackgroundColor:
                  currentForceInDataset?.pointBackgroundColor,
                pointBorderColor: "transparent",
                pointHoverBackgroundColor: "transparent",
                pointHoverBorderColor: "transparent",
                data: grades
                  .find(
                    (currentGrade) =>
                      Number(currentGrade.id) === Number(newForce!.id)
                  )
                  ?.grades?.map((grade) => Math.round(grade)),
              });
            }
            return newForce;
          });
          return { ...prev, datasets: datasets };
        });
      }
    } else if (forcesToRemove?.length && isMobile) {
      let datasets = data.datasets.filter(
        (forceElement) =>
          Number(forceElement.id) !==
          Number(
            forcesToRemove.find(
              (forceToRemove: IForceFromOrbat) =>
                Number(forceToRemove.id) === Number(forceElement.id)
            )?.id
          )
      );
      if (datasets.length === 0) {
        datasets = initializeThresholdAndLabels().datasets;
      }
      setData((prev: IChartData) => ({ ...prev, datasets: datasets }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grades]);
  useEffect(() => {
    if (forcesToRemove?.length) {
      let datasets = data.datasets.filter(
        (forceElement) =>
          Number(forceElement.id) !==
          Number(
            forcesToRemove?.find(
              (forceToRemove: IForceFromOrbat) =>
                Number(forceToRemove.id) === Number(forceElement.id)
            )?.id
          )
      );
      setData((prev: IChartData) => ({ ...prev, datasets: datasets }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forcesToRemove]);
  useEffect(() => {
    const getRadarGrades = () => {
      radarData?.map((radarItem: IRadarInfo) => {
        let sortedIndicators = sortByLabelId(radarItem.indicators, labels);
        sortedIndicators = sortedIndicators.filter((element: any) =>
          labels?.find((label) => Number(label.id) === Number(element.id))
        );
        allGrades.push({
          id: radarItem.id,
          grades: sortedIndicators?.length
            ? sortedIndicators?.map((indicator: IRadarData) => indicator.grade)
            : labels?.map(() => 0),
        });
        return radarItem.indicators?.map(
          (indicator: IIndicatorResult) => indicator.grade
        );
      });

      return allGrades;
    };
    if (selectedForces) {
      setGrades(() => getRadarGrades());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radarData]);
  const checkIsForceExist = (
    forceToAdd: (IForceFromOrbat | undefined)[],
    datasets: IDatasetsChart[]
  ): boolean => {
    let isForceExist = false;
    forceToAdd.map((force: IForceFromOrbat | undefined) => {
      if (!isForceExist) {
        isForceExist = !!datasets.find(
          (item) => Number(item.id) === Number(force?.id)
        );
        return isForceExist;
      }
      return isForceExist;
    });
    return isForceExist;
  };

  return (
    <div className={`radarContainer ${props.className}`} id={props.id}>
      {loading ? (
        <Spinner />
      ) : labels?.length !== 0 ? (
        props.hidden ? null : (
          <Radar
            datasetKeyProvider={(e) => e.id}
            data={data}
            options={options}
            height={100}
          />
        )
      ) : (
        <div className="notFoundText">
          {planId || plan ? (
            <NotFoundPlan text={t("notFoundPlanText")} />
          ) : (
            <NotFoundPlan text={t("noActivePlanSelected")} />
          )}
        </div>
      )}
    </div>
  );
};

export default RadarGraph;
