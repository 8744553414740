// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar-component {
  height: 95%;
  width: 95%;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Charts/Bar/BarChart.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,UAAU;EACV,kBAAkB;EAClB,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":[".bar-component {\n  height: 95%;\n  width: 95%;\n  position: relative;\n  padding-top: 10px;\n  padding-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
