import React, { useContext, useEffect, useRef, useState } from "react";
import { Route } from "react-router-dom";
import { Redirect, useLocation } from "react-router-dom";

// css
import "./DataHistoryTabs.css";

// ionic imports
import {
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonRouterOutlet,
  IonRow,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";

// interfaces
import DataHistory from "./DataHistory";

import { useTranslation } from "react-i18next";
import PMLoading from "../../../components/Shared/Alert/PMLoading";
import { IDateRange } from "../../../Interfaces/IDatePicker";
import IForceTreeNode from "../../../Interfaces/IForceTreeNode";
import useForcesChecked from "../../../components/CustomHooks/useForcesChecked";
import PMLabel from "../../../components/themeComponents/PMLabel";
import { routes } from "../../../services/routeRoles";
import usePlans from "../../../components/CustomHooks/usePlans";
import { useDataHistoryTabsHeaders } from "../../../components/CustomHooks/useDataHistoryTabsHeaders";
import useNavigation from "../../../components/CustomHooks/useNavigation";
import IStationDetails from "../../../Interfaces/IStationDetails";
import IPlan from "../../../Interfaces/IPlan";
import { SystemModeContext } from "../../../context/SystemModeContext/SystemModeContext";
import { EAppMode } from "../../../Enums/EAppMode";
import { ITabOptions } from "../../../Interfaces/IDataHistory";
import { StationsContext } from "../../../context/StationsContext/StationsContext";

const DataHistoryTabs: React.FC = (): JSX.Element => {
  const location = useLocation();
  const { stations } = useContext(StationsContext);
  const [tabsStations, setTabsStations] = useState<IStationDetails[]>([]);
  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);
  const { appMode } = useContext(SystemModeContext);
  const { t } = useTranslation();
  const { replace } = useNavigation();
  const [selectedSoldier, setSelectedSoldier] = useState<IForceTreeNode[]>(
    [] as IForceTreeNode[]
  );
  const [inputType, setInputType] = useState<"date" | "plan">("date");
  const { tabsOptions } = useDataHistoryTabsHeaders();

  //default current day.
  const [rangeDate, setRangeDate] = useState<IDateRange | undefined>({
    startDate: new Date(new Date().setHours(0, 0, 0, 0)),
    endDate: new Date(new Date().setHours(23, 59, 59)),
  });

  let isMustHaveSelectedPlan = false;

  //custom Hooks
  const { onPlanSelectedHandler, plansOptions, selectedPlan } = usePlans(
    undefined,
    isMustHaveSelectedPlan
  );
  const { forcesToRemove, newForces } = useForcesChecked(selectedSoldier);

  const onPlanSelected = (option: IPlan) => {
    onPlanSelectedHandler(option);
    setRangeDate(undefined);
  };
  const onDateChanged = (rangeDate: IDateRange) => {
    setRangeDate(rangeDate);
    onPlanSelectedHandler(undefined);
  };
  const [tabsWidth, setTabsWidth] = useState<number>(0);
  const [moreWidth, setMoreWidth] = useState<number>(0);
  const [tabIndex, setMaxTabsIndex] = useState<number>();
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  const closeOpenAccordion = () => {
    //close current open accordion
    setTimeout(() => {
      if (!accordionGroup.current) {
        return;
      }
      const nativeEl = accordionGroup.current;

      nativeEl.value = undefined;
    }, 200);
  };
  useEffect(() => {
    //get the last "More" tab with o be the maximum width od the stations name
    let moreWidth =
      Math.max(
        15,
        ...tabsStations.map((station) => t(station.stationName).length)
      ) * 9;
    setMoreWidth(moreWidth);

    if (accordionGroup) {
      setTabsWidth(window.innerWidth - moreWidth);
    }
  }, [tabsStations, pageWidth]);

  useEffect(() => {
    //set the maximum index of stations according to the training types length
    let tabWidth = moreWidth;
    for (let index = 0; index < tabsStations.length; index++) {
      const item = tabsStations[index];
      let optionArray = tabsOptions.filter((to) => +to.stationId! === +item.id);
      let maxWidth =
        Math.max(15, ...optionArray.map((tab) => t(tab.title).length)) * 9;
      if (tabWidth + maxWidth < tabsWidth) tabWidth += maxWidth;
      else {
        setMaxTabsIndex(
          index === 0 || tabsStations.length === 1 ? tabsStations.length : index
        );
        break;
      }
    }
  }, [tabsStations, tabsWidth, moreWidth]);

  useEffect(() => {
    //sort the stations by how many tabs its has
    if (stations.length && tabsOptions.length) {
      let tabsStations = [
        stations[0],
        ...stations.slice(1, stations.length).sort((a, b) => {
          return (
            tabsOptions.filter((t) => +t.stationId! === +b.id).length -
            tabsOptions.filter((t) => +t.stationId! === +a.id).length
          );
        }),
      ];
      setTabsStations(tabsStations);
    }
  }, [stations, tabsOptions]);

  useEffect(() => {
    //check if page width changed
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const RoutesComp = () => (
    <IonRouterOutlet>
      <Route
        path={routes.dataHistory}
        render={() => (
          <Redirect
            to={`${
              tabsOptions.length ? tabsOptions[0].url : routes.dataHistory
            }`}
          />
        )}
        exact={true}
      />

      {tabsOptions.map((tab) => (
        <Route
          path={tab.url}
          key={tab.tabId}
          render={() => (
            <DataHistory
              forcesToRemove={forcesToRemove}
              newForces={newForces!}
              headers={tab.headersArray!}
              trainingTypeId={tab.tabId}
              trainingTypeName={tab.title}
              rangeDate={rangeDate}
              setRangeDate={onDateChanged}
              selectedSoldier={selectedSoldier}
              setSelectedSoldier={setSelectedSoldier}
              selectedPlan={selectedPlan}
              plansOptions={plansOptions}
              onPlanSelectedHandler={onPlanSelected}
              inputType={inputType}
              setInputType={setInputType}
            ></DataHistory>
          )}
          exact={true}
        />
      ))}
    </IonRouterOutlet>
  );
  return tabsOptions.length ? (
    appMode === EAppMode.WARTAC ? (
      <IonRow id="tabOptionRow">
        {RoutesComp()}
        <IonAccordionGroup
          className="dataHistoryAccordionGroup"
          ref={accordionGroup}
        >
          <IonRow className="backgroundRow">
            {tabsStations
              .slice(0, tabIndex)
              .map((item: IStationDetails, index: number) => {
                let optionArray = tabsOptions.filter(
                  (tt) => +tt.stationId! === +item.id
                );
                let maxWidth =
                  Math.max(
                    15,
                    ...optionArray.map((tab) => t(tab.title).length)
                  ) * 9;
                return optionArray.length > 1 ? (
                  <IonAccordion
                    className="tabItemDataHistoryAccordion"
                    key={index}
                    value={item.stationName}
                    style={{
                      width: `${maxWidth}px`,
                      maxWidth: `${maxWidth}px`,
                      minWidth: `${maxWidth}px`,
                    }}
                  >
                    <IonItem
                      className={`itemDataHistory paddingDH ${
                        tabsOptions
                          .filter((tt) => +tt.stationId! === +item.id)
                          .map((op) => op.title)
                          .find((ttNAme) =>
                            location.pathname.includes(
                              `${routes.dataHistory}/${ttNAme}`
                            )
                          )
                          ? "tab-selected"
                          : ""
                      }`}
                      button
                      slot="header"
                      style={{
                        width: `${maxWidth}px`,
                        maxWidth: `${maxWidth}px`,
                        minWidth: `${maxWidth}px`,
                      }}
                    >
                      <IonTabButton
                        className="tabButtonDataHistory"
                        style={{
                          width: `${maxWidth - 60}px`,
                          maxWidth: `${maxWidth - 60}px`,
                          minWidth: `${maxWidth - 60}px`,
                        }}
                      >
                        <PMLabel
                          fontColor="light"
                          fontFamily="Light"
                          fontSize="medium"
                          textAlign="center"
                        >
                          {t(item.stationName)}
                        </PMLabel>
                      </IonTabButton>
                    </IonItem>
                    <div className="dataHistoryAccContent" slot="content">
                      {optionArray.map((tt, index) => (
                        <IonItem
                          button
                          lines="none"
                          key={index}
                          className={`itemDataHistory ${
                            location.pathname.includes(`${tt.url}`)
                              ? "inner-tab-selected"
                              : ""
                          }`}
                          style={{
                            width: `${maxWidth}px`,
                            maxWidth: `${maxWidth}px`,
                            minWidth: `${maxWidth}px`,
                          }}
                          onClick={() => {
                            replace(tt.url);
                            closeOpenAccordion();
                          }}
                        >
                          <IonTabButton
                            className="tabButtonDataHistory"
                            style={{
                              width: `${maxWidth}px`,
                              maxWidth: `${maxWidth}px`,
                              minWidth: `${maxWidth}px`,
                            }}
                          >
                            <PMLabel
                              fontColor="light"
                              fontFamily="Light"
                              fontSize="medium"
                              textAlign="start"
                            >
                              {t(tt.title)}
                            </PMLabel>
                          </IonTabButton>
                        </IonItem>
                      ))}
                    </div>
                  </IonAccordion>
                ) : tabsOptions.filter((tt) => +tt.stationId! === +item.id)
                    .length === 1 ? (
                  <IonItem
                    button
                    className={`itemDataHistory ${
                      location.pathname.includes(
                        `${routes.dataHistory}/${
                          tabsOptions.find((tt) => +tt.stationId! === +item.id)!
                            .title
                        }`
                      )
                        ? "tab-selected"
                        : ""
                    }`}
                    style={{
                      width: `${maxWidth}px`,
                      maxWidth: `${maxWidth}px`,
                      minWidth: `${maxWidth}px`,
                    }}
                    key={index}
                    onClick={() => {
                      replace(
                        tabsOptions.find((tt) => +tt.stationId! === +item.id)!
                          .url
                      );
                      closeOpenAccordion();
                    }}
                  >
                    <IonTabButton
                      className="tabButtonDataHistory"
                      style={{
                        width: `${maxWidth}px`,
                        maxWidth: `${maxWidth}px`,
                        minWidth: `${maxWidth}px`,
                      }}
                    >
                      <PMLabel
                        fontColor="light"
                        fontFamily="Light"
                        fontSize="medium"
                        textAlign="center"
                      >
                        {t(
                          tabsOptions.find((tt) => +tt.stationId! === +item.id)!
                            .title
                        )}
                      </PMLabel>
                    </IonTabButton>
                  </IonItem>
                ) : null;
              })}
            {tabIndex &&
            tabsStations.slice(tabIndex, tabsStations.length).length ? (
              <IonAccordion
                className="tabItemDataHistoryAccordion"
                value={"more"}
                style={{
                  width: `${moreWidth}px`,
                  maxWidth: `${moreWidth}px`,
                  minWidth: `${moreWidth}px`,
                }}
              >
                <IonItem
                  slot="header"
                  className={`itemDataHistory paddingDH ${
                    tabsStations
                      .slice(tabIndex, tabsStations.length - 1)
                      .find((station) =>
                        tabsOptions
                          .filter((tt) => +tt.stationId! === +station.id)
                          .map((op) => op.title)
                          .find((ttNAme) =>
                            location.pathname.includes(
                              `${routes.dataHistory}/${ttNAme}`
                            )
                          )
                      )
                      ? "tab-selected"
                      : ""
                  } `}
                  button
                  style={{
                    with: "100%",
                  }}
                >
                  <IonTabButton className="tabButtonDataHistory">
                    <PMLabel
                      fontColor="light"
                      fontFamily="Light"
                      fontSize="medium"
                      textAlign="center"
                    >
                      {t("more")}
                    </PMLabel>
                  </IonTabButton>
                </IonItem>
                <div className="dataHistoryAccContent" slot="content">
                  {tabsStations
                    .slice(tabIndex, tabsStations.length)
                    .map((station, index) => {
                      return (
                        <IonItem
                          button
                          key={index}
                          className={`itemDataHistory ${
                            tabsOptions
                              .filter((tt) => +tt.stationId! === +station.id)
                              .map((op) => op.title)
                              .find((ttNAme) =>
                                location.pathname.includes(
                                  `${routes.dataHistory}/${ttNAme}`
                                )
                              )
                              ? "inner-tab-selected"
                              : ""
                          }`}
                          style={{
                            width: `${moreWidth}px`,
                            maxWidth: `${moreWidth}px`,
                            minWidth: `${moreWidth}px`,
                          }}
                          onClick={() => {
                            replace(
                              tabsOptions.find(
                                (tt) => +tt.stationId! === +station.id
                              )!.url
                            );
                            closeOpenAccordion();
                          }}
                        >
                          <IonTabButton
                            className="tabButtonDataHistory"
                            style={{
                              width: `${moreWidth}px`,
                              maxWidth: `${moreWidth}px`,
                              minWidth: `${moreWidth}px`,
                            }}
                          >
                            <PMLabel
                              fontColor="light"
                              fontFamily="Light"
                              textAlign="start"
                              fontSize="medium"
                            >
                              {t(station.stationName)}
                            </PMLabel>
                          </IonTabButton>
                        </IonItem>
                      );
                    })}
                </div>
              </IonAccordion>
            ) : null}
          </IonRow>
        </IonAccordionGroup>
      </IonRow>
    ) : (
      <IonTabs className="pm-tabs">
        {RoutesComp()}
        <IonTabBar
          slot="top"
          className="ion-justify-content-start reportGeneratorTabs"
        >
          {tabsOptions.map((item: ITabOptions, index: number) => {
            return (
              <IonTabButton
                className={location.pathname === item.url ? "tab-selected" : ""}
                tab={item.title}
                href={item.url}
                key={index}
              >
                <PMLabel
                  fontColor="light"
                  fontFamily="Light"
                  fontSize="medium"
                  textAlign="center"
                >
                  {t(item.title)}
                </PMLabel>
              </IonTabButton>
            );
          })}
        </IonTabBar>
      </IonTabs>
    )
  ) : (
    <PMLoading
      isOpen={!tabsOptions.length}
      message={t("loadingData")}
      spinner="bubbles"
    ></PMLoading>
  );
};
export default DataHistoryTabs;
