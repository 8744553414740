import { FC, useState } from "react";
import "./SearchDropdown.css";
import Select, { components } from "react-select";
import { IonIcon, IonLabel } from "@ionic/react";
import { searchOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import EIconsSrc from "../../../Interfaces/EIconsSrc";

interface Option {
  label: string | null;
  value: any;
}
interface IProps {
  options: Option[] | [];
  onSelect: (option: Option | null) => void;
  showIcon: boolean | true;
  Placeholder?: string;
  title?: string | " ";
  defaultValue: Option | undefined;
  hasTitle?: boolean;
  disabled?: boolean | false;
  isSettingsPage?: false | boolean;
  isUsersPage?: false | boolean;
  dark?: boolean;
  isForDropDown?: boolean;
  isForcesTreeSearch?: boolean;
  height?: number;
}
const MIN_STRING_LENGTH = 2;
const MIN_NUMBER_LENGTH = 4;
const MAX_DISPLAYED_OPTIONS = 80;

const Dropdown: FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [isTODisplayDropdown, setIsToDisplayDropdown] = useState<boolean>(
    !props.isForcesTreeSearch
  );
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const [maxDisplayedOptions, setMaxDisplayedOptions] = useState<number>(
    MAX_DISPLAYED_OPTIONS
  );
  let el: HTMLBodyElement | null = document.querySelector("body");
  let style: any;
  if (el) style = window.getComputedStyle(el);

  /**
   *  checks if the user entered MIN_STRING_LENGTH characters of string or MIN_NUMBER_LENGTH characters of number
   */
  const isMinimumSearchedCharts = (searchText: string): boolean => {
    //if the dropdown is not in the forces tree display all options
    if (!props.isForcesTreeSearch) return true;
    if (
      (isNaN(Number(searchText)) && searchText.length < MIN_STRING_LENGTH) ||
      (!isNaN(Number(searchText)) && searchText.length < MIN_NUMBER_LENGTH)
    )
      return false;
    return true;
  };

  const customFilter = (option: any, searchText: string) => {
    if (typeof option.value === "string") {
      return option.label.toLowerCase().includes(searchText.toLowerCase());
    } else if (typeof option.value === "object") {
      option.value["force_type"] = t(option.value["force_type"]);
      if (!isMinimumSearchedCharts) return false;
      let status: boolean = true;
      let objectAsString = Object.keys(option.value)
        .filter(
          (key) =>
            option.value[key] !== null &&
            option.value[key] !== undefined &&
            key !== "id" &&
            key !== "parent"
        )
        .map((key) => option.value[key])
        .join(" ")
        .toLowerCase();

      //  if one of the search text doesn't includes in the object the object should not be present
      let searchTextAsArray = searchText
        .toLowerCase()
        .replace(/^0+/, "")
        .split(" ");
      searchTextAsArray.forEach((searchWord) => {
        if (!objectAsString.includes(searchWord)) status = false;
      });

      return status;
    }
  };
  // select styles
  const customStyles = {
    placeholder: (base: any) => ({
      ...base,
      color: !style
        ? "#ffffff99"
        : style.getPropertyValue("--ion-color-fontWhite"),
      paddingTop: "0",
      paddingRight: props.isSettingsPage || props.isUsersPage ? "6px" : "0",
      paddingLeft: props.isSettingsPage || props.isUsersPage ? "3%" : "0",

      fontSize: "16px",
      fontFamily:
        props.isSettingsPage || props.isUsersPage
          ? "Regular !important"
          : "Light !important",
    }),
    input: (base: any) => ({
      ...base,
      color: "white",
      paddingTop: props.isSettingsPage || props.isUsersPage ? "10%" : "0",
      paddingRight: props.isSettingsPage || props.isUsersPage ? "3%" : "0",
      paddingLeft: props.isSettingsPage || props.isUsersPage ? "3%" : "0",
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      zIndex: "9 !important",
      display: "none",
    }),
    container: (base: any) => ({
      ...base,
      width: "100%",
      textAlign: "start",
    }),
    control: (base: any) => ({
      ...base,
      height: props.height
        ? props.height
        : props.isSettingsPage || props.isUsersPage
        ? 50
        : 55,
      borderRadius: props.isSettingsPage ? "8px" : "2px",
      color: !style ? "white" : style.getPropertyValue("--ion-color-fontLight"),
      backgroundColor: !style
        ? "#enable"
        : props.isSettingsPage
        ? "#3a3c41"
        : props.isForDropDown
        ? "#3a3c41"
        : style.getPropertyValue("--ion-color-highlighted"),
      border: "0",
      boxShadow: props.isSettingsPage
        ? null
        : style.getPropertyValue("--box-shadow"),
      opacity: props.disabled ? 0.3 : 1,

      "&:hover": {
        border: "0",
      },
    }),
    menu: (base: any) => ({
      ...base,
      backgroundColor: !style
        ? "#44474e"
        : style.getPropertyValue("--ion-color-enable"),
      zIndex: "9 !important",
      display: isTODisplayDropdown ? "all" : "none",
    }),
    singleValue: (base: any) => ({
      ...base,
      color: !style
        ? "#ffffff99"
        : style.getPropertyValue("--ion-color-fontWhite"),

      margin: "0",
      paddingInlineStart:
        props.isSettingsPage || props.isUsersPage ? "10px" : "0",
      paddingRight: props.isSettingsPage || props.isUsersPage ? "6px" : "0",
      paddingLeft: props.isSettingsPage || props.isUsersPage ? "3%" : "0",
      fontFamily: "Light",
      paddingTop: props.isSettingsPage || props.isUsersPage ? "17px" : "0",
    }),
    valueContainer: (base: any) => ({
      ...base,
      height: "100%",
      paddingInlineStart:
        props.isSettingsPage || props.isUsersPage ? "2px" : "8px",
    }),

    menuList: (base: any) => ({
      ...base,
      zIndex: "9 !important",
      "::-webkit-scrollbar": {
        width: "5px",
      },
      "::-webkit-scrollbar-track": {
        background: !style
          ? "#44474e"
          : style.getPropertyValue("--ion-color-enable"),
      },
      "::-webkit-scrollbar-thumb": {
        background: !style
          ? "#888"
          : style.getPropertyValue("--ion-color-medium"),
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: !style
          ? "#555"
          : style.getPropertyValue("--ion-color-medium-tint"),
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      zIndex: "10 !important",
      color: style
        ? style.getPropertyValue("--ion-color-fontWhite")
        : "#ffffff99",
      fontFamily: "Regular !important",

      backgroundColor: !style
        ? state.isSelected
          ? "#6a6c70"
          : "#44474e"
        : state.isSelected
        ? style.getPropertyValue("--ion-color-medium")
        : style.getPropertyValue("--ion-color-enable"),
      "&:hover": {
        backgroundColor: style
          ? style.getPropertyValue("--ion-color-dropDownSearch")
          : "#6a6c70",
      },
      "&:active": {
        backgroundColor: style
          ? style.getPropertyValue("--ion-color-medium")
          : "#6a6c70",
      },
    }),
  };

  // Set the search icon on the search field
  const DropdownIndicator = (base: any) => {
    return (
      <components.DropdownIndicator {...base}>
        {(props.isSettingsPage || props.isUsersPage) &&
        (!props.defaultValue?.label ||
          props.defaultValue?.label === null ||
          !props.options.filter(
            (o: Option) => o.label === props.defaultValue?.label
          ).length) &&
        !isFocus ? null : (
          <IonLabel className={`labelTitle ${props.disabled && "disabled"}`}>
            {props.title}
          </IonLabel>
        )}
        {!(props.isSettingsPage || props.isUsersPage) && (
          <IonLabel className={`labelTitle ${props.disabled && "disabled"}`}>
            {props.title}
          </IonLabel>
        )}
        {props.showIcon ? (
          <IonIcon icon={searchOutline} />
        ) : (
          <IonIcon
            icon={EIconsSrc.TRIANGLE}
            className={
              base.selectProps.menuIsOpen
                ? `traingle-icon-down ${props.disabled ? "disabled" : ""}`
                : `traingle-icon-up ${props.disabled ? "disabled" : ""}`
            }
          />
        )}
      </components.DropdownIndicator>
    );
  };
  const onInputChange = (searchText: string) => {
    //display maximum MAX_DISPLAYED_OPTIONS results
    setMaxDisplayedOptions(MAX_DISPLAYED_OPTIONS);

    let options = props.options.filter((option) =>
      customFilter(option, searchText)
    );

    if (options.length) setFilteredOptions(options);

    //display dropdown only if the user entered minimum number of characters
    setIsToDisplayDropdown(isMinimumSearchedCharts(searchText));
  };

  return (
    <Select
      onBlur={() => setIsFocus(false)}
      onFocus={() => setIsFocus(true)}
      value={{
        value: "searchForForce",
        label:
          (props.isSettingsPage || props.isUsersPage) &&
          (!props.defaultValue?.label || props.defaultValue?.label === null) &&
          !isFocus
            ? props.title
            : props.showIcon
            ? t("searchForForce")
            : "",
      }}
      styles={customStyles}
      options={
        filteredOptions.length
          ? filteredOptions.slice(0, maxDisplayedOptions)
          : props.options.slice(0, maxDisplayedOptions)
      }
      onChange={props.onSelect}
      placeholder={
        (props.isSettingsPage || props.isUsersPage) &&
        (!props.defaultValue?.label || props.defaultValue?.label === null) &&
        !isFocus
          ? props.title
          : props.showIcon
          ? t("searchForForce")
          : ""
      }
      components={{ DropdownIndicator }}
      onInputChange={onInputChange}
      filterOption={customFilter}
      isDisabled={props.disabled}
      onMenuScrollToBottom={() => {
        // if came to the button scroll loads more
        setMaxDisplayedOptions((prev) => prev + MAX_DISPLAYED_OPTIONS);
      }}
      onMenuScrollToTop={() => {
        //reset to the MAX_DISPLAYED_OPTIONS
        setMaxDisplayedOptions(MAX_DISPLAYED_OPTIONS);
      }}
    />
  );
};

export default Dropdown;
