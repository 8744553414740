// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  color: #ffff;
  font-family: Bold !important;
  margin-inline-start: 20px;
}

.radarQ {
  height: 100% !important;
  width: 100%;
  margin-top: 1%;

  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Desktop/PerformanceDisplay/PerformanceTabs/PerformanceIndicators/PerformanceIndicators.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,4BAA4B;EAC5B,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,WAAW;EACX,cAAc;;EAEd,aAAa;EACb,uBAAuB;AACzB","sourcesContent":["h1 {\n  color: #ffff;\n  font-family: Bold !important;\n  margin-inline-start: 20px;\n}\n\n.radarQ {\n  height: 100% !important;\n  width: 100%;\n  margin-top: 1%;\n\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
