import { useContext, useEffect, useState } from "react";
import ITrendIndicator from "../../Interfaces/ITrendIndicator";
import ILabel from "../../Interfaces/ILabel";
import IThreshold from "../../Interfaces/IThreshold";
import IPlan from "../../Interfaces/IPlan";
import { IPlanValues } from "../../Interfaces/IPlanValues";
import { useTranslation } from "react-i18next";
import { IndicatorsContext } from "../../context/IndicatorsContext/IndicatorsContext";
import { TrainingTypesContext } from "../../context/TrainingTypesContext/TrainingTypesContext";

const useInitGraphsData = (
  plansValuesForSelectedPlan: IPlanValues | undefined,
  plans?: IPlan[]
) => {
  const { t } = useTranslation();
  const { indicators } = useContext(IndicatorsContext);
  const { trainingTypes } = useContext(TrainingTypesContext);
  const [thresholdsCompetency, setThresholdsCompetency] = useState<
    IThreshold[]
  >([] as IThreshold[]);
  const [labelsCompetency, setLabelsCompetency] = useState<ILabel[]>(
    [] as ILabel[]
  );
  const [thresholdsDetails, setThresholdsDetails] = useState<IThreshold[]>(
    [] as IThreshold[]
  );
  const [labelsDetails, setLabelsDetails] = useState<ILabel[]>([] as ILabel[]);
  //trends
  const [thresholdsTrends, setThresholdsTrends] = useState<IThreshold[]>(
    [] as IThreshold[]
  );
  const [trendsIndicators, setTrendsIndicators] = useState<ITrendIndicator[]>(
    [] as ITrendIndicator[]
  );
  const [labelsTrends, setLabelsTrends] = useState<ILabel[]>([] as ILabel[]);

  useEffect(() => {
    if (plans?.length) {
      setLabelsTrends(
        [
          ...plans
            .filter((p) => p.isGlobal)
            .sort((previousValue: IPlan, currentValue: IPlan) => {
              return (
                (previousValue.globalPlanId || 0) -
                (currentValue.globalPlanId || 0)
              );
            }),

          ...plans
            .filter((p) => !p.isGlobal)
            .sort((previousValue: IPlan, currentValue: IPlan) => {
              return (
                new Date(previousValue.startDate!).getTime() -
                new Date(currentValue.startDate!).getTime()
              );
            }),
        ].map((plan) => {
          let startDay = new Date(plan.startDate!).getDate();
          let endDay = new Date(plan.endDate!).getDate();
          let startMonth = new Date(plan.startDate!).getMonth() + 1;
          let endMonth = new Date(plan.endDate!).getMonth() + 1;
          let dateText: string;
          if (startMonth === endMonth) {
            dateText =
              endDay + "." + endMonth + " - " + startDay + "." + startMonth;
          } else {
            dateText =
              endDay + "." + endMonth + " - " + startDay + "." + startMonth;
          }
          return {
            id: plan.id,
            label: plan.name + " " + dateText,
            isSelected: plan.isGlobal,
          };
        })
      );

      setThresholdsTrends(
        plans.map((plan) => ({
          id: +plan.id,
          threshold: +plan.lethality,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans]);

  useEffect(() => {
    if (indicators.length)
      setTrendsIndicators([
        { id: -1, name: t("lethality") },
        ...indicators.map((indicator) => ({
          id: +indicator.id,
          name: indicator.name,
        })),
      ]);
    if (plansValuesForSelectedPlan) {
      if (indicators.length) {
        let indicatorsInPlan = indicators.filter((indicator) =>
          plansValuesForSelectedPlan.indicatorsPlanValues.find(
            (indicatorPlanValue) =>
              +indicatorPlanValue.indicatorId === +indicator.id &&
              indicatorPlanValue.weight !== null
          )
        );

        setLabelsCompetency(
          indicatorsInPlan
            .sort((a, b) => +a.id - +b.id)
            .map((indicator) => ({
              id: +indicator.id,
              label: indicator.name,
            }))
        );
        setThresholdsCompetency(
          plansValuesForSelectedPlan.indicatorsPlanValues
            .sort((a, b) => +a.indicatorId - +b.indicatorId)
            .filter((indicator) => indicator.weight)
            .map((indicator) => ({
              id: indicator.indicatorId,
              threshold: Number(indicator.threshold || 0),
            }))
        );
      }
      if (trainingTypes.length) {
        let trainingTypesInPlan = trainingTypes.filter((trainingType) =>
          plansValuesForSelectedPlan.trainingTypesPlanValues.find(
            (trainingTypePlanValue) =>
              +trainingTypePlanValue.trainingTypeId === +trainingType.id &&
              trainingTypePlanValue.weight !== null
          )
        );

        setLabelsDetails(
          trainingTypesInPlan
            .sort((a, b) => +a.id - +b.id)
            //get the training types that has weight
            .filter(
              (trainingType) =>
                plansValuesForSelectedPlan.trainingTypesPlanValues.find(
                  (t) => Number(t.trainingTypeId) === Number(trainingType.id)
                )?.weight
            )

            .map((trainingType) => ({
              id: +trainingType.id,
              label: t(trainingType.name.trim()),
            }))
        );
        setThresholdsDetails(
          plansValuesForSelectedPlan.trainingTypesPlanValues
            .sort((a, b) => +a.trainingTypeId - +b.trainingTypeId)
            .filter((trainingType) => trainingType.weight)
            .map((trainingType) => ({
              id: +trainingType.trainingTypeId,
              threshold: Number(trainingType.threshold),
            }))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansValuesForSelectedPlan, indicators, trainingTypes]);

  return {
    thresholdsCompetency,
    labelsCompetency,
    thresholdsTrends,
    trendsIndicators,
    labelsTrends,
    thresholdsDetails,
    labelsDetails,
  };
};

export default useInitGraphsData;
