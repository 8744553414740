// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cellInputValue {
  height: 65%;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}
.cellInputError {
  height: 35%;
  padding: 0px;
  display: flex;
  justify-content: flex-start;

  border: var(--ion-color-danger);
  border-width: 0px;
  border-top-width: 1px;
  border-style: solid;
  color: var(--ion-color-danger);
}
.cellInput {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.cellInput p {
  margin: 0;
  line-height: 1;
  display: flex;
  padding-inline-start: 8px;
  font-family: "Regular";
}
.cellInputValue p {
  font-size: small;
  align-items: flex-end;
  margin-bottom: 3px;
}
.cellInputError p {
  width: 100%;
  font-size: x-small;
  align-items: center;
  margin-bottom: 1px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/InputWithError/InputWithError.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,UAAU;EACV,aAAa;EACb,2BAA2B;AAC7B;AACA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,2BAA2B;;EAE3B,+BAA+B;EAC/B,iBAAiB;EACjB,qBAAqB;EACrB,mBAAmB;EACnB,8BAA8B;AAChC;AACA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,SAAS;EACT,cAAc;EACd,aAAa;EACb,yBAAyB;EACzB,sBAAsB;AACxB;AACA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".cellInputValue {\n  height: 65%;\n  padding: 0;\n  display: flex;\n  justify-content: flex-start;\n}\n.cellInputError {\n  height: 35%;\n  padding: 0px;\n  display: flex;\n  justify-content: flex-start;\n\n  border: var(--ion-color-danger);\n  border-width: 0px;\n  border-top-width: 1px;\n  border-style: solid;\n  color: var(--ion-color-danger);\n}\n.cellInput {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.cellInput p {\n  margin: 0;\n  line-height: 1;\n  display: flex;\n  padding-inline-start: 8px;\n  font-family: \"Regular\";\n}\n.cellInputValue p {\n  font-size: small;\n  align-items: flex-end;\n  margin-bottom: 3px;\n}\n.cellInputError p {\n  width: 100%;\n  font-size: x-small;\n  align-items: center;\n  margin-bottom: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
