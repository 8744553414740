import { useEffect, useState } from "react";
import {
  initializeRows,
  IbasicRow,
  refreshRows,
  resetRowsData,
} from "../Desktop/DataPosting/dataPostingHelpers";
import { useSoldiersInLanes } from "./HooksWithRQ/useSoldiersInLane";
import { useWeapons } from "./HooksWithRQ/useWeapons";
import { useGetTrainees } from "./HooksWithRQ/useGetTrainees";

const UseDataPosting = <T extends IbasicRow>(
  XDefaultRow: T,
  rowsNumber: number,
  trainingTypeId?: number,
  isMrkOrIEST?: boolean
) => {
  //useState
  const [rows, setRows] = useState<T[]>([]);
  const [isSuccess, setIsSuccess] = useState<number>(0);
  const [isHeatsModalOpen, setIsHeatsModalOpen] = useState<boolean>(false);

  //custom Hooks
  const { weaponsTypesList } = useWeapons();
  const soldiersInLanesQuery = useSoldiersInLanes(trainingTypeId);
  const soldiersInLanes = soldiersInLanesQuery?.data ?? [];

  //useContext
  const { soldiersNamesAndIds } = useGetTrainees();

  useEffect(() => {
    if (weaponsTypesList && weaponsTypesList.length > 0) {
      let options = {} as T;

      setRows((prev) =>
        initializeRows(
          rowsNumber,
          XDefaultRow,
          {
            ...options,
            soldiersInLanes: soldiersInLanes,
            allSoldiers: soldiersNamesAndIds,
            weapons: weaponsTypesList.map((weaponType) => weaponType),
          },
          prev,
          isMrkOrIEST,
          soldiersInLanes
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weaponsTypesList, soldiersInLanes, soldiersNamesAndIds, soldiersInLanes]);

  const refreshRowsWrap = (XDefaultRow: T) => {
    soldiersInLanesQuery.refetch();
    refreshRows(
      setRows,
      rowsNumber,
      XDefaultRow,
      weaponsTypesList,
      isMrkOrIEST,
      soldiersInLanes
    );
  };

  const resetRowsDataWrap = (XDefaultRow: T) => {
    resetRowsData(
      setRows,
      rowsNumber,
      XDefaultRow,
      isMrkOrIEST,
      soldiersInLanes,
      {
        soldiersInLanes: soldiersInLanes,
        allSoldiers: soldiersNamesAndIds,
        weapons: weaponsTypesList.map((weaponType) => weaponType),
      }
    );
  };

  useEffect(() => {
    resetRowsDataWrap(XDefaultRow);
    // eslint-disable-next-line
  }, [isSuccess]);
  return {
    rows,
    setRows,
    isSuccess,
    setIsSuccess,
    weaponsTypesList,
    isHeatsModalOpen,
    soldiersInLanes,
    // setSoldierInlanes,
    setIsHeatsModalOpen,
    refreshRowsWrap,
    resetRowsDataWrap,
  };
};
export default UseDataPosting;
