// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simpleSelectDropdown {
  background-color: var(--ion-color-enable);
  color: var(--ion-color-fontXLight);
  width: 100%;
  border: none !important;
  border-color: transparent;
  padding-inline-start: 5px;
  margin-top: 4px;
}
.simpleSelectDropdown,
*:focus {
  outline: none;
}

.simpleSelectDropdown::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/DataPosting/SimpleSelect.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,kCAAkC;EAClC,WAAW;EACX,uBAAuB;EACvB,yBAAyB;EACzB,yBAAyB;EACzB,eAAe;AACjB;AACA;;EAEE,aAAa;AACf;;AAEA;EACE,UAAU;EACV,WAAW;AACb","sourcesContent":[".simpleSelectDropdown {\n  background-color: var(--ion-color-enable);\n  color: var(--ion-color-fontXLight);\n  width: 100%;\n  border: none !important;\n  border-color: transparent;\n  padding-inline-start: 5px;\n  margin-top: 4px;\n}\n.simpleSelectDropdown,\n*:focus {\n  outline: none;\n}\n\n.simpleSelectDropdown::-webkit-scrollbar {\n  width: 7px;\n  height: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
