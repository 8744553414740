import { FC, useContext, useEffect, useState } from "react";
import "./BarChart.css";
import "chartjs-plugin-zoom";
import "chartjs-plugin-datalabels";
import { Bar, defaults } from "react-chartjs-2";
import IChartData from "../../../../Interfaces/IChartData";
import ILegendChartItem from "../../../../Interfaces/ILegendChartItem";
import IDatalabelsChart from "../../../../Interfaces/IDatalabelsChart";
import IForceFromOrbat from "../../../../Interfaces/IForceFromOrbat";
import IChartGrade from "../../../../Interfaces/IChartGrade";
import ILabel from "../../../../Interfaces/ILabel";
import IThreshold from "../../../../Interfaces/IThreshold";
import IStationChartData from "../../../../Interfaces/IStationChartData";
import IToolTipChartItem from "../../../../Interfaces/IToolTipChartItem";
import IDatasetsChart from "../../../../Interfaces/IDatasetsChart";
import { useTranslation } from "react-i18next";
import NotFoundPlan from "../../../../pages/Desktop/NotFound/NotFoundPlan";
import { useHistory } from "react-router-dom";
import IPlan from "../../../../Interfaces/IPlan";
import { UserContext } from "../../../../context/UserContext/userContext";
import { sortByLabelId, translateString } from "../../../../services/helpers";
import IStationsChart from "../../../../Interfaces/IStationsChart";
import Spinner from "../../Spinner/Spinner";
import { ITooltipElementsResult } from "../../../../Interfaces/dataCalculator";
import { AppContext } from "../../../../context/AppContext/AppContext";
import ELanguage from "../../../../Enums/ELanguage";
import classNames from "classnames";

interface IProps {
  chartData?: IChartData | undefined;
  thresholds?: IThreshold[] | undefined;
  labels?: ILabel[] | undefined;
  barChartData?: IStationsChart[] | undefined;
  selectedForces?: (IForceFromOrbat | undefined)[] | undefined;
  newForces?: (IForceFromOrbat | undefined)[] | undefined;
  forcesToRemove?: IForceFromOrbat[] | undefined;
  barsType: string;
  id?: string;
  className?: string;
  isModal?: boolean;
  color?: string;
  isDesktop?: boolean;
  setDataForMobileLabels?: (data: IDatasetsChart[]) => void;
  plan: IPlan | undefined;
  onBarClick?: (
    plan: IPlan | undefined,
    forceId: number,
    trainingTypeId: number,
    forceName: string,
    trainingTypeName: string
  ) => void;
  trends?: boolean;
  isDisableZoom?: boolean;
}
const PLANS = "plans";
const TRAINING_TYPES = "trainingTypes";
const notAttendedFontColor = "#FF3D3D";
const attendedFontColor = "#ffffff";

const BarChart: FC<IProps> = (props: IProps): JSX.Element => {
  const history = useHistory();

  const {
    thresholds,
    labels,
    barChartData,
    selectedForces,
    newForces,
    forcesToRemove,
    barsType,
    isModal,
    color,
    setDataForMobileLabels,
    isDesktop,
    plan,
    onBarClick,
    isDisableZoom,
  } = props;

  const { t, i18n } = useTranslation();

  const [data, setData] = useState<IChartData>({
    labels: [],
    datasets: [],
  });
  const { isTaggingActive } = useContext(UserContext);
  const [grades, setGrades] = useState<IChartGrade[]>();
  const [loading, setLoading] = useState<boolean>(true);
  let allGrades: IChartGrade[] = [];
  const { isAsDesktop } = useContext(AppContext);

  const isMobile: boolean = !isAsDesktop;
  useEffect(() => {
    let timer = setTimeout(() => setLoading(false), 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (isMobile && setDataForMobileLabels) {
      setDataForMobileLabels([
        ...data.datasets.filter(
          (data) =>
            newForces?.find((force) => Number(force?.id) === Number(data.id)) ||
            Number(data.id) === 0
        ),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const initializeThresholdAndLabels = () => {
    let newData: IChartData = data;
    let sortedLabels = labels?.map((l) => ({ ...l, label: t(l.label) }));
    let sortedThresholds = sortByLabelId(thresholds, labels);
    sortedThresholds = sortedThresholds.filter((element: any) =>
      labels?.find((label) => Number(label.id) === Number(element.id))
    );
    newData["labels"] = sortedLabels?.map(
      (labelItem: ILabel) => labelItem.label
    );
    newData.datasets[0] = {
      id: 0,
      label: t("thresholdForOperationalIndicators"),
      borderColor: "rgb(0,176,117)",
      pointBackgroundColor: "rgb(0,176,117)",
      pointBorderColor: "rgb(0,176,117)",
      borderWidth: isMobile ? 1.5 : 3.5,
      hoverBackgroundColor: "rgb(0,176,117)",
      hoverBorderColor: "rgb(0,176,117)",
      hoverBorderWidth: 4,
      hoverRadius: 17,
      pointHoverBorderColor: "rgb(0,176,117)",
      type: "scatter",
      pointStyle: "line",
      pointRadius: isMobile ? 3 : 17,
      data: sortedThresholds?.map(
        (thresholdItem: IThreshold) => thresholdItem.threshold
      ),
    };
    return newData;
  };

  useEffect(() => {
    if (thresholds && labels) {
      setData(() => initializeThresholdAndLabels());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thresholds, labels]);

  useEffect(() => {
    if (selectedForces) {
      setGrades(() => getGrades());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels]);

  const getGrades = () => {
    barChartData?.map((element: IStationsChart) => {
      let sortedData = sortByLabelId(element.gradesArray, labels);
      sortedData = sortedData?.filter((element: IStationsChart) =>
        labels?.find((label) => Number(label.id) === Number(element.id))
      );

      allGrades.push({
        id: element.id,
        grades: sortedData?.length
          ? sortedData?.map((station: IStationChartData) => station.grade)
          : labels?.map(() => 0),
        colors: sortedData?.length
          ? sortedData?.map((station: IStationChartData) =>
              station.isAttended || Number(station.grade) !== 0
                ? attendedFontColor
                : notAttendedFontColor
            )
          : labels?.map(() =>
              element.isAttended && !sortedData?.length
                ? attendedFontColor
                : notAttendedFontColor
            ),
      });

      return element.gradesArray?.map(
        (station: IStationChartData) => station.grade
      );
    });
    return allGrades;
  };

  useEffect(() => {
    if (selectedForces) {
      setGrades(() => getGrades());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barChartData]);

  defaults.global.datasets.bar.categoryPercentage = 0.7;
  defaults.global.datasets.bar.barPercentage = 0.6;
  defaults.global.datasets.bar.maxBarThickness = isMobile ? 10 : 15;

  useEffect(() => {
    // Check if this is good or necessary to first of all remove the force and then add new one.
    if (isTaggingActive) {
      if (forcesToRemove?.length) {
        setData((prev: IChartData) => {
          let datasets = prev.datasets.filter(
            (forceElement) =>
              Number(forceElement.id) !==
              Number(
                forcesToRemove.find(
                  (forceToRemove: IForceFromOrbat) =>
                    Number(forceToRemove.id) === Number(forceElement.id)
                )?.id
              )
          );

          return { ...prev, datasets: datasets };
        });
      }
    }
    if (newForces?.length && grades?.length) {
      if (!checkIsForceExist(newForces, data.datasets)) {
        setData((prev: IChartData) => {
          let datasets = prev.datasets;
          newForces.forEach((newForce) => {
            if (newForce)
              datasets.push({
                id: newForce!.id,
                label: newForce!.name,
                backgroundColor:
                  isModal === true && color ? color : newForce!.color,
                borderColor:
                  isModal === true && color ? color : newForce!.color,
                hoverBackgroundColor:
                  isModal === true && color ? color : newForce!.color,
                hoverBorderColor:
                  isModal === true && color ? color : newForce!.color,
                borderWidth: 3.5,
                cornerRadius: 10,
                colors: grades.find(
                  (currentGrade) =>
                    Number(currentGrade.id) === Number(newForce!.id)
                )?.colors,
                data: grades
                  .find(
                    (currentGrade) =>
                      Number(currentGrade.id) === Number(newForce!.id)
                  )
                  ?.grades?.map((grade) => Math.round(grade)),
              });
          });

          return { ...prev, datasets: datasets };
        });
      } else {
        setData((prev: IChartData) => {
          let datasets = prev.datasets;
          selectedForces?.map((newForce: IForceFromOrbat | undefined) => {
            let index = 0;
            let currentForceInDataset: IDatasetsChart | undefined =
              datasets.find((datasetItem, datasetIndex) => {
                index = datasetIndex;
                return Number(datasetItem.id) === Number(newForce!.id);
              });

            if (currentForceInDataset) {
              datasets.splice(index, 1);
              datasets.push({
                id: newForce!.id,
                label: newForce!.name,
                backgroundColor: currentForceInDataset?.backgroundColor,
                borderColor: currentForceInDataset?.borderColor,
                hoverBackgroundColor:
                  currentForceInDataset?.hoverBackgroundColor,
                hoverBorderColor: currentForceInDataset?.hoverBorderColor,
                borderWidth: 3.5,
                cornerRadius: 10,
                data: grades
                  .find(
                    (currentGrade) =>
                      Number(currentGrade.id) === Number(newForce!.id)
                  )
                  ?.grades?.map((grade) => Math.round(grade)),
                colors: grades.find(
                  (currentGrade) =>
                    Number(currentGrade.id) === Number(newForce!.id)
                )?.colors,
                // tooltipsData: grades.find(
                //   (currentGrade) =>
                //     Number(currentGrade.id) === Number(newForce!.id)
                // )?.tooltipsData,
              });
            }
            return newForce;
          });

          return { ...prev, datasets: datasets };
        });
      }
    }

    if (!isTaggingActive) {
      if (forcesToRemove?.length) {
        setData((prev: IChartData) => {
          let datasets = prev.datasets.filter(
            (forceElement) =>
              Number(forceElement.id) !==
              Number(
                forcesToRemove.find(
                  (forceToRemove: IForceFromOrbat) =>
                    Number(forceToRemove.id) === Number(forceElement.id)
                )?.id
              )
          );
          return { ...prev, datasets: datasets };
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grades]);

  const clickHandler = (event: any, elements: any) => {
    let planId;
    let index: number = elements[0]?._index;
    if (index !== undefined && labels !== undefined) {
      planId = labels[index].id;
    }

    if (barsType === PLANS && isDesktop && planId) {
      history.push({
        pathname: "/performance/stations",
        state: {
          data: planId,
        },
      });
    } else if (barsType === TRAINING_TYPES && isDesktop && planId) {
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const label = labels?.find(
        (label) => label.label === elements[1]?._model.label
      );
      if (onBarClick && dataset.id !== 0 && label)
        onBarClick(
          plan,
          dataset.id,
          Number(label?.id!),
          dataset.label,
          label?.label!
        );
    }
  };

  const checkIsForceExist = (
    forceToAdd: (IForceFromOrbat | undefined)[],
    datasets: IDatasetsChart[]
  ): boolean => {
    let isForceExist = false;
    forceToAdd?.map((force): boolean => {
      if (force)
        if (!isForceExist) {
          isForceExist = !!datasets.find(
            (item) => Number(item.id) === Number(force!.id)
          );
          return isForceExist;
        }
      return isForceExist;
    });
    return isForceExist;
  };

  // const datasetKeyProvider = () => Math.random();
  const options = {
    legend: {
      display: !isMobile,
      rtl: i18n.language === ELanguage.he,
      position: "bottom",
      labels: {
        boxWidth: 7,
        fontSize: isModal === true ? 14 : 20,
        fontColor: "rgba(255, 255, 255, 0.89)",
        padding: 32,
        filter: (item: ILegendChartItem) => (item.pointStyle = "circle"),
        usePointStyle: true,
      },
    },
    events: ["click", "mousemove"],
    onClick: clickHandler,
    responsive: true,

    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            autoSkip: true,
            fontColor: "rgba(255, 255, 255, 0.89)",
            stepSize: 10,
            suggestedMax: 100,
            fontSize: isMobile ? 12 : isModal === true ? 10 : 14,
          },
          gridLines: {
            color: "rgba(205,205,205,0.3)",
            zeroLineColor: "rgba(205,205,205,0.3)",
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontColor: "rgba(255, 255, 255, 0.89)",
            stepSize: 10,
            suggestedMax: 100,
            fontSize: isMobile ? 12 : isModal === true ? 16 : 16,
            minRotation: 0,
            maxRotation: props.trends ? 90 : 45,
          },
          cornerRadius: 10,
          barRoundness: 2,
        },
      ],
    },
    plugins: {
      labels: {
        render: () => "",
      },
      datalabels: {
        align: (context: IDatalabelsChart) => {
          return i18n.language === ELanguage.he
            ? Number(context.dataset.data![context.dataIndex]) > 96
              ? 35
              : -35
            : Number(context.dataset.data![context.dataIndex]) > 96
            ? 90
            : -90;
        },
        offset: (context: IDatalabelsChart) =>
          i18n.language === ELanguage.he
            ? Number(context.dataset.data![context.dataIndex]) === 100
              ? 5
              : Number(context.dataset.data![context.dataIndex]) > 96
              ? 2.5
              : Number(context.dataset.data![context.dataIndex]) < 10 &&
                isMobile
              ? -2
              : isMobile
              ? 0
              : 3
            : Number(context.dataset.data![context.dataIndex]) === -100
            ? -5
            : Number(context.dataset.data![context.dataIndex]) > 96
            ? -2.5
            : Number(context.dataset.data![context.dataIndex]) < 10 && isMobile
            ? -2
            : isMobile
            ? 0
            : -3,
        anchor: "end",
        clamp: true,
        fontSize: 1,
        font: {
          size: isMobile ? 9 : 12,
        },
        color: (context: any) => {
          var index = context.dataIndex;
          var value = context.dataset.colors[index];
          return value;
        },
        display: (context: IDatalabelsChart) =>
          context.dataset.type !== "scatter",
      },

      zoom: {
        pinch: {
          enabled: isDisableZoom ? false : true,
          mode: "x",
        },
        pan: {
          enabled: isDisableZoom ? false : true,
          mode: "x",
          speed: 5,
        },
        zoom: {
          enabled: isDisableZoom ? false : true,
          speed: 10,
          mode: "x",
          min: 2,
          max: 5,
        },
      },
    },
    maintainAspectRatio: false,
    tooltips: {
      backgroundColor: "#36383E",
      titleFontColor: "rgba(255,255,255,0.89)",
      bodyFontColor: "rgba(255,255,255,0.89)",
      footerFontColor: "rgba(255,255,255,0.89)",
      footerFontSize: 12,
      footerAlign: t("startTooltipAlign"),
      titleAlign: t("startTooltipAlign"),
      titleFontSize: isMobile ? 12 : 18,
      displayColors: false,
      titleFontFamily: "Light",
      footerFontFamily: "Light",
      mode: "nearest",
      bodyAlign: t("startTooltipAlign"),
      callbacks: {
        footer: (tooltipItem: IToolTipChartItem[], data: IChartData) => {
          let currentTrainingTypeTooltip: string = "";
          let forceData = barChartData?.find(
            (forceData: IStationsChart) =>
              Number(forceData.id) ===
              Number(data.datasets[tooltipItem[0].datasetIndex].id)
          );
          let currTrainingType = labels?.find(
            (label) => label.label === tooltipItem[0].label
          );
          let trainingType = forceData?.gradesArray?.find(
            (trainingType) => +trainingType.id === Number(currTrainingType?.id)
          );
          if (trainingType?.tooltipResults.standardDeviation !== undefined)
            currentTrainingTypeTooltip = `${t("avgGrade")}: ${
              tooltipItem[0].yLabel
            }\n${t("standardDeviation")}: ${
              trainingType?.tooltipResults.standardDeviation
            }`;

          return tooltipItem[0].datasetIndex === 0
            ? ""
            : barsType === TRAINING_TYPES
            ? currentTrainingTypeTooltip
            : "";
        },

        label: (tooltipItem: IToolTipChartItem, data: IChartData) => {
          let forceData = barChartData?.find(
            (forceData: IStationsChart) =>
              Number(forceData.id) ===
              Number(data.datasets[tooltipItem.datasetIndex].id)
          );

          let currTrainingType = labels?.find(
            (label) => label.label === tooltipItem.label
          );
          let trainingTypeTooltips: ITooltipElementsResult[] | undefined =
            forceData?.gradesArray?.find(
              (trainingType) =>
                +trainingType.id === Number(currTrainingType?.id)
            )?.tooltipResults.tooltipElementsResults;

          let currentTrainingTypeTooltip: string[] = [];
          let isTab = false;
          if (trainingTypeTooltips) {
            trainingTypeTooltips?.forEach((tooltip) => {
              if (tooltip.value !== undefined)
                if (tooltip.value !== undefined) {
                  if (tooltip.value !== null) {
                    let value =
                      tooltip.value === null
                        ? t("noValue")
                        : translateString(tooltip.value, t);

                    currentTrainingTypeTooltip.push(
                      `${isTab ? "   " : ""}${t(tooltip.name)} : ${value}`
                    );
                  }
                } else {
                  isTab = true;
                  if (tooltip.name === "") {
                    currentTrainingTypeTooltip.push(t(tooltip.name));
                    isTab = false;
                  } else currentTrainingTypeTooltip.push(t(tooltip.name) + ":");
                }
            });
          }
          return tooltipItem.datasetIndex === 0
            ? tooltipItem.label + ": " + translateString(tooltipItem.value, t)
            : barsType === TRAINING_TYPES
            ? currentTrainingTypeTooltip
            : isMobile
            ? ""
            : t("presentDetails");
        },
      },
    },
  };

  return (
    <div className={`bar-component ${props.className}`} id={props.id}>
      {loading ? (
        <Spinner />
      ) : labels?.length !== 0 ? (
        // <div
        //   style={{ width: "100%", overflowX: "scroll" }}
        //   className={classNames("scrollS")}
        // >
        //   <div style={{ width: "1800px", height: "100%" }}>
        <Bar
          // width={1800}
          data={data}
          datasetKeyProvider={(e) => e.id}
          height={160}
          options={options}
          plugins={[]}
        />
      ) : //   </div>
      // </div>
      plan ? (
        <NotFoundPlan text={t("notFoundPlanText")} />
      ) : (
        <NotFoundPlan text={t("noActivePlanSelected")} />
      )}
    </div>
  );
};

export default BarChart;
