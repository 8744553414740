import React from "react";
import DropdownEditor from "./DropdownEditor";
import DrillsDataTable from "./DrillsDataTable";
import { useTranslation } from "react-i18next";
import DesktopManualInputTemplate from "./DesktopManualInputTemplate";
import {
  convertLaneToMrkANdIESTLane,
  handleSave,
  IbasicRow,
  resetRowsData,
  sendDrillToBBackend,
  XDefaultColumn,
} from "./dataPostingHelpers";
import UseDataPosting from "../../CustomHooks/UseDataPosting";
import {
  ComparisonFormatter,
  PositiveNumberFormatter,
  PositiveNumberNtoMFormatter,
} from "./InputFormatters";
import IDataPosting from "../../../Interfaces/IDataPosting";
import SoldiersListEditor from "./SoldiersListEditor";
import "./Mrk.css";

const messageType: number = 6;

const rowsNumber: number = 14;
const IESTSimulativeShootingTrainingTypeId: number = 13;
const departmentWeaponTrainingTypeId: number = 15;
const BISTSimulativeShootingTrainingTypeId = 19;
// Mrk row (ui) interface
export interface IMRKRow extends IbasicRow {
  laneId: number;
  weaponType: string;
  numberOfShots: number | "";
  numberOfHits: number | "";
  score: number | "";
  grouping: number | "";
  lastHeat: string;
  soldierName: string;
  weapons: string[];
  hashtags: string | "";
}

// Mrk message interface
interface mrkMessage {
  MessageType: number;
  SoldierID: number;
  TimeStamp: Date;
  LaneID: number;
  TrainingTypeID: number;
  WeaponType: string;
  NumOfShots: number | "";
  RedHits: number | "";
  Score: number | "";
  Grouping: number | "";
  LastHeat: string;
  Hashtags: string[];
  DrillStartTime: Date;
  PlanId: number | undefined;
}
interface IProps extends IDataPosting {
  isBist?: boolean;
}
const Mrk: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const trainingTypeId = props.isBist
    ? BISTSimulativeShootingTrainingTypeId
    : IESTSimulativeShootingTrainingTypeId;
  const XDefaultRow: IMRKRow = {
    laneId: 1,
    weaponType: " ",
    numberOfShots: "",
    numberOfHits: "",
    score: "",
    grouping: "",
    lastHeat: "",
    soldierName: "",
    weapons: [],
    hashtags: "",
    soldierId: "",
    soldiersInLanes: [],
  };

  // Columns for each input type
  const columns = [
    {
      ...XDefaultColumn,
      key: "laneId",
      name: t("lane"),
      cellClass: "celClassExtra",
      renderEditCell: undefined,
    },
    {
      ...XDefaultColumn,
      key: "soldierName",
      name: t("soldierName"),
      cellClass: "celClassExtra",
      renderEditCell: SoldiersListEditor,
    },
    {
      ...XDefaultColumn,
      key: "soldierId",
      name: t("militaryId"),
      cellClass: "celClassExtra",
      renderEditCell: SoldiersListEditor,
      renderCell: PositiveNumberFormatter,
    },
    {
      ...XDefaultColumn,
      key: "weaponType",
      name: t("weaponType"),
      renderEditCell: DropdownEditor,
      cellClass: "celClassExtra formatterKey(weaponType)",
    },
    {
      ...XDefaultColumn,
      key: "numberOfShots",
      name: t("numberOfShots"),
      renderCell: PositiveNumberFormatter,
    },
    {
      ...XDefaultColumn,
      key: "numberOfHits",
      name: t("numberOfHits"),
      renderCell: (event: any) => (
        <ComparisonFormatter comparisonKey="numberOfShots" event={event} />
      ),
    },
    {
      ...XDefaultColumn,
      key: "score",
      name: t("score"),
      renderCell: (event: any) => (
        <PositiveNumberNtoMFormatter
          minToMax={{ max: 100, min: 0 }}
          event={event}
        />
      ),
    },
    {
      ...XDefaultColumn,
      key: "grouping",
      name: t("grouping"),
      renderCell: (event: any) => (
        <PositiveNumberNtoMFormatter
          minToMax={{ max: 20, min: 0 }}
          event={event}
        />
      ),
    },
    {
      ...XDefaultColumn,
      key: "lastHeat",
      name: t("lastHeat"),
      cellClass: "celClassExtra",
    },
    {
      ...XDefaultColumn,
      key: "hashtags",
      name: t("hashtags"),
      cellClass: "celClassExtra",
    },
  ];

  const {
    isSuccess,
    setIsSuccess,
    rows,
    setRows,
    refreshRowsWrap,
    resetRowsDataWrap,
  } = UseDataPosting(XDefaultRow, rowsNumber, trainingTypeId, true);

  // Manages data sending
  const sendData = async (row: IMRKRow) => {
    // To avoid sending empty rows, check if all elements exist
    if (
      (row.numberOfHits !== "" ||
        row.numberOfShots !== "" ||
        row.score !== "" ||
        row.grouping !== "") &&
      row.soldierId !== ""
    ) {
      // Sets the loading
      setIsSuccess(-1);
      let dbLaneId: number | undefined;
      let rowToSend: mrkMessage;
      let weaponType = row.weaponType === " " ? "None" : row.weaponType;

      const trainingType =
        !props.isBist && row.weapons.indexOf(row.weaponType) >= 4
          ? departmentWeaponTrainingTypeId
          : trainingTypeId;
      dbLaneId = await convertLaneToMrkANdIESTLane(row.laneId);
      // If a lane matches, it creates the row to send
      if (dbLaneId) {
        rowToSend = {
          SoldierID: row.soldierId,
          MessageType: messageType,
          TimeStamp: new Date(),
          TrainingTypeID: trainingType,
          LaneID: dbLaneId,
          WeaponType: weaponType,
          NumOfShots: row.numberOfShots,
          RedHits: row.numberOfHits,
          Score: row.score,
          Grouping: row.grouping,
          LastHeat: row.lastHeat,
          Hashtags: row.hashtags.split(" "),
          DrillStartTime: new Date(),
          PlanId: props.selectedPlan?.id,
        };
        // Then sends it
        sendDrillToBBackend(
          rowToSend,
          () => {
            setIsSuccess(200);
            props.resetCompetencyForces();
          },
          () => setIsSuccess(500)
        );
      }
    }
  };

  return (
    <DesktopManualInputTemplate
      selectedPlan={props.selectedPlan}
      plansOptions={props.plansOptions}
      onPlanSelectedHandler={props.onPlanSelectedHandler}
      pageName={"IEST_Simulative_Shooting"}
      onClickThirdButton={() => {
        handleSave(rows, sendData, t);
      }}
      onClickSecondButton={() => {
        refreshRowsWrap(XDefaultRow);
      }}
      onClickFirstButton={() => {
        resetRowsDataWrap(XDefaultRow);
      }}
    >
      <DrillsDataTable
        columns={columns}
        isSuccess={isSuccess}
        resetRows={() => {
          resetRowsData(setRows, rowsNumber, XDefaultRow, true);
        }}
        setRows={setRows}
        rows={rows}
        isDataPosting
      />
    </DesktopManualInputTemplate>
  );
};

export default Mrk;
