import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./SyncProgressBar.css";

import { baseUrlPMBackend, environment } from "../../../Configurations/consts";
import PMLabel from "../../themeComponents/PMLabel";
import Axios from "../../../Axios";
import { SyncContext } from "../../../context/SyncContext/SyncContext";
import HorizontalProgressBar from "../../Shared/HorizontalProgressBar/HorizontalProgressBar";
import { UserContext } from "../../../context/UserContext/userContext";
import EEnvironment from "../../../Enums/EEnvironment";
import { SystemModeContext } from "../../../context/SystemModeContext/SystemModeContext";
import { EAppMode } from "../../../Enums/EAppMode";

const SyncProgressBar: React.FC = () => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(UserContext);
  const { syncStarted, syncEnded, setSyncStarted, setSyncEnded } =
    useContext(SyncContext);
  const { appMode } = useContext(SystemModeContext);
  const [progressInterval, setProgressInterval] = useState<NodeJS.Timeout>();

  const [progress, setProgress] = useState<number>(0);
  const [syncInprogress, setSyncInProgress] = useState<boolean>(false);

  useEffect(() => {
    if (syncEnded) {
      setProgress(100);
      setSyncInProgress(false);
      progressInterval && clearInterval(progressInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncEnded]);

  useEffect(() => {
    if (syncStarted) {
      setSyncEnded(false);
      setProgress((prev) => (prev >= 100 ? 0 : prev));
      setSyncInProgress(true);
    } else {
      setSyncInProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncStarted]);

  useEffect(() => {
    if (syncStarted || syncInprogress) {
      let intervalId = setInterval(() => {
        progressInterval && clearInterval(progressInterval);

        Axios.get(`${baseUrlPMBackend}syncData/getProgress`)
          .then((res) => {
            setSyncStarted(true);
            setProgress(res.data.progress);
          })
          .catch((err) => {
            setSyncInProgress(false);
            setSyncStarted(false);
            progressInterval && clearInterval(progressInterval);
          });
      }, 3000);
      setProgressInterval(intervalId);
    } else {
      progressInterval && clearInterval(progressInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncStarted, syncInprogress]);

  useEffect(() => {
    if (
      isAdmin &&
      environment === EEnvironment.localSite &&
      appMode === EAppMode.WARTAC
    )
      setSyncInProgress(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, environment]);

  useEffect(() => {
    return () => {
      progressInterval && clearInterval(progressInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="syncProgressbar">
      {syncInprogress && syncStarted ? (
        <>
          <PMLabel fontSize="medium" fontColor="light" fontFamily="Light">
            {t(`syncProgress`)}
          </PMLabel>
          <div className="progressbar">
            <HorizontalProgressBar progress={progress}></HorizontalProgressBar>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default SyncProgressBar;
