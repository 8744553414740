import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { IonItem } from "@ionic/react";
import PMLoading from "../../Shared/Alert/PMLoading";
import customToast from "../../Shared/Toast/CustomToast";
import { useTranslation } from "react-i18next";
import PopoverMenu from "../../Shared/Popover/PopoverMenu";
import IFilters from "../../../Interfaces/IFilters";
import Spinner from "../../Shared/Spinner/Spinner";
import "./DrillsDataTable.css";
import { findChangedElement } from "../../../services/helpers";
import i18n from "../../../services/i18next";
import "react-data-grid/lib/styles.css";
import DataGrid from "react-data-grid";
import ELanguage from "../../../Enums/ELanguage";

interface inputProps {
  columns: any[];
  rows: any[];
  setRows?: Dispatch<SetStateAction<any[]>>;
  setRowsToSave?: Dispatch<SetStateAction<any[]>>;
  isSuccess?: number;
  resetRows?: () => void;
  setLoadMore?: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: boolean;
  selectedRows?: number[];
  setSelectedRows?: Dispatch<SetStateAction<number[]>>;
  isWithFilter?: boolean;
  filters?: IFilters;
  isDataPosting?: boolean;
  onDoubleClick?: undefined | ((row: any) => void);
  isToDisplayDetails?: boolean;
  setEditRow?: React.Dispatch<React.SetStateAction<boolean>>;
  isEditRow?: boolean;
  setIsSuccess?: React.Dispatch<React.SetStateAction<number>>;
}

const DrillsDataTable: FC<inputProps> = (props: inputProps) => {
  const {
    columns,
    filters,
    isDataPosting,
    isLoading,
    isSuccess,
    isWithFilter,
    resetRows,
    selectedRows,
    setLoadMore,
    setRows,
    setSelectedRows,
    setRowsToSave,
    setIsSuccess,
  } = props;

  const { t } = useTranslation();
  const rowHeight = 35;
  const doubleClickMS = 300;
  const headerRowHeight = isWithFilter ? 80 : 50;
  const [duplicateColumn, setDuplicateColumn] = useState<string>("");
  const [crtlClicked, setCrtlClicked] = useState<boolean>(false);
  const [rowToPresent, serRowToPresent] = useState<any>();
  const [rowClicked, setRowClicked] = useState<{
    rowId: number;
    time: number;
  }>({
    rowId: 0,
    time: 0,
  });
  const [popoverState, setShowPopover] = useState<{
    showPopover: boolean;
    event: MouseEvent | undefined;
    duplicateValue: string;
  }>({
    showPopover: false,
    event: undefined,
    duplicateValue: "",
  });

  //temporary there is a bug in the package waiting for a fix
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message === "Script error." ||
        e.message ===
          "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);
  // Manages the toasts
  useEffect(() => {
    if (isSuccess) {
      if (isSuccess !== 0 && isSuccess !== -1)
        if (isSuccess === 200) {
          customToast.success(t("dataSendSuccess"));
          if (resetRows) resetRows();
        } else customToast.error(t("dataSendError"));
    }
    setIsSuccess && setIsSuccess(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const handleScroll = (e: any) => {
    if (
      e.target.scrollTop > 0 && //make sure scroll happened
      setLoadMore &&
      Math.floor(e.target.scrollHeight - e.target.scrollTop) -
        Math.floor(e.target.clientHeight) <
        2
    ) {
      setLoadMore((prev) => (prev === false ? true : prev));
    }
  };

  document.oncontextmenu = function (e: MouseEvent) {
    if (!isDataPosting) return;
    let element = e.target as HTMLInputElement;

    if (
      element.style.cssText.includes("grid-column-start:") ||
      element.className.includes("formatterKey")
    ) {
      if (element.style.cssText.includes("grid-column-start:")) {
        setDuplicateColumn(
          columns[Number(element.getAttribute("aria-colindex")) - 1].key
        );
      } else {
        // in case there is forrmater
        let key = element.className.slice(
          element.className.indexOf("(") + 1,
          element.className.lastIndexOf(")")
        );
        setDuplicateColumn(key);
      }
      e.preventDefault();
      setShowPopover({
        showPopover: true,
        event: e,
        duplicateValue: element.value ? element.value : element.innerText,
      });
    }
  };

  const duplicateTags = () => {
    setRows
      ? setRows(
          props.rows.map((row) => {
            return { ...row, [duplicateColumn]: popoverState.duplicateValue };
          })
        )
      : console.log();

    setShowPopover({
      showPopover: false,
      event: undefined,
      duplicateValue: "",
    });
  };
  //when new data arrive reset the rows to present
  useEffect(() => {
    !props.rows.length && serRowToPresent(undefined);
  }, [props.rows]);
  /**
   * check double click on row and set the row to present
   * on one click select the row
   * @param rowNumber
   * @param row
   * @param event
   */
  const onRowClicked = (rowNumber: any, row: any, event: any) => {
    //can select different row only if edit process is off
    if (!props.isEditRow) {
      if (
        Date.now() - rowClicked.time < doubleClickMS &&
        props.isToDisplayDetails
      ) {
        serRowToPresent({ ...row });
      } else setRowClicked({ rowId: row.rowIndex, time: Date.now() });
      if (props.setSelectedRows)
        props.setSelectedRows((prev: number[]) => {
          if (crtlClicked) {
            if (prev?.includes(row.drillId))
              return prev.filter((rowId) => rowId !== row.drillId);
            return [...prev, row.drillId];
          } else {
            if (prev?.includes(row.drillId) && prev.length === 1) return [];
            return [row.drillId];
          }
        });
    }
  };

  const onRowsChanged = (changedRows: any[]) => {
    const changedItem = findChangedElement(props.rows, changedRows)?.element;
    if (changedItem) {
      setRowsToSave!((prev: any[]) => changeUnsavedItems(prev, changedItem));
      setRows!(changedRows);
    }
  };

  const changeUnsavedItems = (
    modifiedArray: any[],
    currentItem: any
  ): any[] => {
    const { soldierId: id, result } = currentItem;
    let newArray: any[] = [...modifiedArray];
    if (result === "") {
      newArray = removeItemById(id, modifiedArray);
    } else if (!isAlreadyChanged(id, modifiedArray)) {
      newArray.push({ ...currentItem });
    } else if (isResultChanged(id, result, modifiedArray)) {
      newArray = modifiedArray.map((item: any) =>
        item.soldierId === id ? { ...item, result: result } : item
      );
    }
    return newArray;
  };

  const removeItemById = (id: number, list: any[]): any[] =>
    list.filter((item: any) => item.soldierId !== id);

  const isAlreadyChanged = (id: number, list: any[]): boolean =>
    !!list.find((item: any) => item.soldierId === id);

  const isResultChanged = (
    id: number,
    currentResult: string,
    list: any[]
  ): boolean =>
    !(
      list.find((item: any) => item.soldierId === id)!.result === currentResult
    );

  const isINFilters = (
    line: { [key: string]: string },
    filters: IFilters
  ): boolean => {
    let isInclude = true;
    Object.keys(filters).forEach((key) => {
      if (
        filters[key] !== undefined &&
        !String(line[key]).includes(filters[key])
      ) {
        isInclude = false;
      }
    });
    return isInclude;
  };

  document.addEventListener("keydown", function (event) {
    setCrtlClicked(event.ctrlKey);
  });
  document.addEventListener("keyup", function (event) {
    setCrtlClicked(event.ctrlKey);
  });

  //**if there is not data to present load more  */
  useEffect(() => {
    if (!isWithFilter || !filters) return;
    let rows = props.rows.filter((line: any) => isINFilters(line, filters!));
    if (props.rows.length && !rows.length && setLoadMore) setLoadMore(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rows, filters]);

  useEffect(() => {
    if (rowToPresent)
      setRows &&
        setRows((prev) => {
          let newRowsArray = [...prev];
          let detailsIndex = newRowsArray.findIndex(
            (row) =>
              row.DrillId === `${rowToPresent.DrillId}Details` ||
              (row.DrillId.includes("Details") &&
                row.DrillId === rowToPresent.DrillId)
          );
          let rowIndex = newRowsArray.findIndex(
            (row) => row.DrillId === rowToPresent.DrillId
          );
          if (detailsIndex > -1) {
            newRowsArray.splice(detailsIndex, 1);
          } else {
            newRowsArray.splice(rowIndex + 1, 0, {
              ...rowToPresent,
              DrillId: `${rowToPresent.DrillId}Details`,
              isDetails: true,
            });
          }
          return newRowsArray;
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowToPresent]);

  return (
    <div className="DrillsGridDiv">
      <PMLoading
        isOpen={isSuccess === -1}
        message={t("sendingData")}
        spinner={"bubbles"}
      />
      <div className="gridDiv">
        <PopoverMenu
          popoverState={popoverState}
          onDismiss={() =>
            setShowPopover({
              showPopover: false,
              event: undefined,
              duplicateValue: "",
            })
          }
        >
          <IonItem button onClick={duplicateTags} className={"duplicateItem"}>
            <p className="menuText menuTextGeneral">
              {t("duplicateToAllRows")}
            </p>
          </IonItem>
        </PopoverMenu>
        <div className="gridWrap">
          <DataGrid
            rowKeyGetter={rowKeyGetter}
            direction={i18n.language === ELanguage.he ? "rtl" : "ltr"}
            columns={columns}
            rowClass={(row: any) => {
              if (selectedRows && selectedRows?.includes(row.drillId))
                return "rowSelected";
            }}
            className={`${
              "inputGrid " +
              (!props.isDataPosting ? " fixedHeight" : "") +
              (setSelectedRows ? " withRowSelection" : "")
            }`}
            style={{
              height:
                (filters && isWithFilter
                  ? props.rows.filter((line: any) =>
                      isINFilters(line, filters!)
                    )
                  : props.rows
                ).length *
                  rowHeight +
                headerRowHeight +
                2,
            }}
            // onSelectedRowsChange={(rows) => onRowClicked(1, 1, 1)}
            onScroll={(e: any) => {
              handleScroll(e);
            }}
            rows={
              filters && isWithFilter
                ? props.rows.filter((line: any) => isINFilters(line, filters!))
                : props.rows
            }
            onRowsChange={(changedRows) =>
              setRows && (!isWithFilter || props.isEditRow)
                ? setRowsToSave
                  ? onRowsChanged(changedRows)
                  : setRows(changedRows)
                : () => {}
            }
            rowHeight={rowHeight}
            headerRowHeight={headerRowHeight}
            onCellClick={(props, event) => {
              props.selectCell(true);
              onRowClicked(props.row.rowIndex, props.row, event);
            }}
          />
        </div>
        {isLoading ? <Spinner className={"spinner-container"} /> : null}
      </div>
    </div>
  );
};
export default DrillsDataTable;
function rowKeyGetter(row: any) {
  return row.DrillId ? row.DrillId : row.id;
}
