// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pmTextEditor {
  background-color: transparent !important;
  width: 100%;
  border: none;
  margin-inline-start: 6px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.disableEditor {
  pointer-events: none;
  cursor: default;
}
`, "",{"version":3,"sources":["webpack://./src/components/Desktop/DataPosting/DataHistoryTextEditor.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,WAAW;EACX,YAAY;EACZ,wBAAwB;EACxB,iBAAiB;EACjB,sBAAsB;EACtB,yBAAyB;EACzB,qBAAqB;AACvB;AACA;EACE,oBAAoB;EACpB,eAAe;AACjB","sourcesContent":[".pmTextEditor {\n  background-color: transparent !important;\n  width: 100%;\n  border: none;\n  margin-inline-start: 6px;\n  user-select: none;\n  -moz-user-select: none;\n  -webkit-user-select: none;\n  -ms-user-select: none;\n}\n.disableEditor {\n  pointer-events: none;\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
